import React, { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useLocation, useParams, useNavigate } from "react-router-dom"
import {useOs} from '@wppopen/react';
import { Container, Grid } from "@mui/material"
import { connect } from "react-redux"
import { WppBanner, WppButton, WppIconAdd, WppToast } from "@wppopen/components-library-react"
import serviceURL from "../../../helper/serviceURL"
import { AssessmentListProps, rowDataProps } from "./interface"
import CreateAssessment from "./createAssessment/CreateAssessment"
import styles from "./AssessmentList.module.scss"
import RowDetails from "./rowDetails/RowDetails"
import DataGridComp from "../../../components/dataGrid/DataGridComp"
import isObjectEmpty, { convertToTitleCase, getLocalDate } from "../../../helper/Helper"
import DataGridOfflineComp from "../../../components/dataGrid/DataGridOfflineComp"
import { ASSESSMENT_SEARCHS, ASSES_GRID_HEADER_DETAILS, USER_ROLE } from "../../../helper/constants"
import SearchComp from "../../../components/search/SearchComp"
import SearchResultComp from "../../../components/search/SearchResultComp"
import { searchData } from "../../../helper/interface"
import { launchAssessDispatcher } from "../../dmModule/inventoryList/rowDetails/action"
import {selectedSideNavOrgDispatcher} from '../../sideNav/action';

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    isLaunchAssess: state.launchAssessRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    userData: state.getOrgByUserRed.data,
    userContext: state.getOrgByUserRed.context
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj)),
  selectedSideNavOrgDispatcher: (org: object) => dispatch(selectedSideNavOrgDispatcher(org)),
})

const AssessmentList = React.memo(
  ({
    base64Email,
    isLaunchAssess,
    selectedSideOrg,
    launchAssessDispatcher,
    userData,
    userContext,
    selectedSideNavOrgDispatcher
  }: AssessmentListProps) => {
    const [isModalOpen, setModalStatus] = useState(false)
    const initialRowData = {
      id: "",
      name: "",
      description: "",
      inventoryId: "",
      inventoryType: "",
      categories: "",
      status: true
    }
    const pageToStart = 0
    const pageSize = 25
    const [showToast, setShowToast] = useState(false)
    const [showApproveToast, setShowApproveToast] = useState(false)
    const [clickedRowData, setClickedRowData] = useState<any>(initialRowData)
    const [openClickedRowModal, setOpenClickedRowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isToShowBanner, setIsToShowBanner] = useState(false)
    const [showPermissionBanner, setShowPermissionBanner] = useState(false)
    const [totalElements, setTotalElements] = useState(0) // Initialize rowCount to 0
    const [initialGridList, setInitialGridList] = useState([])
    const [searchResults, setSearchResults] = useState<any>([])
    let { assessmentId, orgId, assessmentName } = useParams();
    const navigate = useNavigate();
    const isFetchBySelectType = true // indicate data grid if fetch list based on select dropdown values API is different for list and search
    const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/assessments`
    const {osApi: { getAccessToken }} = useOs();
    const headers = {
      accept: "*/*",
      Authorization: 'Bearer ' + getAccessToken()
    }
    const [queryVal, setQueryVal] = useState("")
    const location = useLocation()

    useEffect(() => {
      if(assessmentId && assessmentName) {
        const currentOrg = userData?.organizations?.find(org => org?.id === Number(orgId))
        selectedSideNavOrgDispatcher(currentOrg)
        setClickedRowData({id: assessmentId, orgId: orgId, name:  decodeURI(assessmentName) })
        setOpenClickedRowModal(true)
      }
    }, [ assessmentId, orgId, assessmentName])

    useLayoutEffect(() => {
      if (isLaunchAssess && isLaunchAssess.openAssess) {
        if (!initialGridList.length) {
          fetchInitialGridList(pageToStart, pageSize)
          setModalStatus(true)
          return
        }
      }
      if (base64Email && selectedSideOrg && selectedSideOrg.id) {
        setInitialGridList([])
        fetchInitialGridList(pageToStart, pageSize)
        setSearchResults([])
      }
      return () => {
        setModalStatus(false)
      }
    }, [base64Email, selectedSideOrg, isLaunchAssess])

    const isSiteAdmin = userData?.groups.some(group => group?.name === USER_ROLE.SAD)
    const isUserAllowedToSeeAssessment = isSiteAdmin
      ? true
      : selectedSideOrg?.name?.toLowerCase() === userContext?.agency?.toLowerCase()

    const fetchInitialGridList = (
      startRow: number = 0,
      endRow: number = pageSize,
      queryString: string = `page=${startRow}&size=${endRow}`
    ) => {
      if (!isUserAllowedToSeeAssessment) {
        setShowPermissionBanner(true)
        return
      }
      setShowPermissionBanner(false)
      let apiUrl = ""
      if (selectedSideOrg) {
        const id = orgId || selectedSideOrg?.id
        apiUrl = baseApiUrl + "/?" + queryString + "&orgId=" + id
        const handleErrors = (response: any) => {
          if (!response.ok) {
            setIsLoading(false)
            setIsToShowBanner(true)
            throw Error(response.statusText)
          }
          return response
        }
        setIsLoading(true)
        fetch(apiUrl, { headers: headers })
          .then(handleErrors)
          .then(httpResponse => httpResponse.json())
          .then(response => {
            if (response) {
              let sanitizedData = prepareRow(response.content)
              if (sanitizedData) {
                setInitialGridList(sanitizedData)
                setTotalElements(response.totalElements)
                setIsToShowBanner(false)
              }
            }
            setIsLoading(false)
          })
          .catch(error => {
            console.error(error)
            setIsLoading(false)
            setIsToShowBanner(true)
          })
      }
    }

    const prepareRow = useCallback((rowData: []) => {
      let rows: any = []
      if (rowData) {
        rowData.forEach((item: rowDataProps) => {
          rows.push({
            id: item.id,
            name: item.name,
            createdBy: item.createdBy,
            createdAt: getLocalDate(item.createdAt),
            status: item.status,
            modifiedAt: getLocalDate(item.modifiedAt),
            modifiedBy: item.modifiedBy,
            description: item.description,
            inventoryType: convertToTitleCase(item.inventoryType?.name),
            respondent: item.respondent && item.respondent.fullName,
            assessmentTemplate: item.assessmentTemplate && item.assessmentTemplate.name,
            assessor: item.assessor && item.assessor.email
          })
        })
      }
      return rows
    }, [])

    const getClickedRow = useCallback(
      (data: object): void => {
        if (!isObjectEmpty(data)) {
          setClickedRowData(data)
          setOpenClickedRowModal(true)
        }
      },
      [clickedRowData]
    )

    useEffect(() => {
      if (location?.state?.id) {
        setClickedRowData(location?.state)
        setOpenClickedRowModal(true)
      }
    }, [location?.state?.id])

    const getFilterQuery = useCallback(
      (val: string, list: object | searchData[]) => {
        setQueryVal(val)
        setSearchResults(list)
      },
      [queryVal, searchResults]
    )

    const handleLaunchAssessment = useCallback(() => {
      setModalStatus(true)
      setShowToast(false)
    }, [])

    const closeSideModal = () => {
      launchAssessDispatcher && launchAssessDispatcher({})
      setModalStatus(false)
      setOpenClickedRowModal(false)
      if (base64Email) {
        fetchInitialGridList(pageToStart, pageSize)
      }
    }
    const getShowToast = (val: any) => {
      setShowToast(val)
      fetchInitialGridList(pageToStart, pageSize)
    }
    const getShowApproveToast = (val: any) => {
      setShowApproveToast(val)
      fetchInitialGridList(pageToStart, pageSize)
    }

    const handleModalClose = () => {
      setOpenClickedRowModal(false)
      navigate('/pia/assessment')
    }

    return (
      <div className={styles.container}>
        <Container maxWidth="xl">
          <div className={styles.loadingArea}>
            <WppBanner id="banner" type="information" show={isToShowBanner}>
              Unable to fetch information. Please refresh or try after some time.
            </WppBanner>
            <WppBanner id="permission-banner" type="information" show={showPermissionBanner}>
              You don't have enough permission to view.
            </WppBanner>
            {showToast && (
              <WppToast className={styles.toast} message={"Assessment created successfully"} type={"success"} />
            )}
            {showApproveToast && (
              <WppToast className={styles.toast} message={"Assessment reviewed successfully"} type={"success"} />
            )}
          </div>
          <Grid container spacing={{ xs: 2, md: 3, sm: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
            {ASSESSMENT_SEARCHS.length > 0 && selectedSideOrg && (
              <SearchComp
                searchList={ASSESSMENT_SEARCHS}
                filterQuery={getFilterQuery}
                initialGridList={initialGridList}
              />
            )}
            {/* {
                        initialGridList.length ?
                            <Grid item xs={12} sm={6} md={6} display="flex" justifyContent="flex-end"
                                  alignSelf="flex-end">
                                <WppButton
                                    className={styles.customBtnSecondary}
                                    variant="secondary" onClick={handleLaunchAssessment} size='s'>
                                    <WppIconAdd slot="icon-start" className={styles.customIcon} />
                                    Create Assessment
                                </WppButton>
                            </Grid>
                            :
                            null
                    } */}
            {selectedSideOrg && (
              <SearchResultComp searchResultList={searchResults} selectedSideOrg={selectedSideOrg.id} />
            )}
            {isModalOpen && (
              <CreateAssessment open={isModalOpen} closeSideModal={closeSideModal} showToast={getShowToast} />
            )}
            <Grid item xs={12} sm={12} md={12} style={{paddingTop: initialGridList.length > 0 ? "3rem" : "9rem", marginBottom: "5rem"}}>
              {isLoading ? (
                <DataGridOfflineComp />
              ) : (
                <>
                  {!showPermissionBanner && selectedSideOrg && (
                    <DataGridComp
                      columnHeaderDefinition={ASSES_GRID_HEADER_DETAILS}
                      initialGridResult={initialGridList}
                      totalElements={totalElements}
                      clickedRow={getClickedRow}
                      baseApiUrl={baseApiUrl}
                      prepareRow={prepareRow}
                      headers={headers}
                      searchList={ASSESSMENT_SEARCHS}
                      queryVal={queryVal}
                      isFetchBySelectType={isFetchBySelectType}
                      createParentModule={handleLaunchAssessment}
                      noCustomRowBtn={true}
                      fetchListUrl={baseApiUrl + "/?" + "&orgId=" + selectedSideOrg.id} //add list of attributes
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {openClickedRowModal && (
            <RowDetails
              open={openClickedRowModal}
              clickedRowData={clickedRowData}
              showApproveToast={getShowApproveToast}
              closeSideModal={closeSideModal}
              onClose={handleModalClose}
            />
          )}
        </Container>
      </div>
    )
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList)
