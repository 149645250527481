import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { Container, Grid } from "@mui/material"
import { connect } from "react-redux"
import { useOs } from "@wppopen/react"
import {
  WppAccordion,
  WppBanner,
  WppProgressIndicator,
  WppSideModal,
  WppSpinner,
  WppTab,
  WppTabs,
  WppTypography,
  WppTag,
  WppButton,
  WppIconAdd
} from "@wppopen/components-library-react"
import { TabsChangeEventDetail } from "@wppopen/components-library"
import { AccordItem, AssessAccordItem } from "../interface"
import { clearAssessmentByIdDispatcher, getAssessmentByIdDispatcher, getInventoryByAssessIdDispatcher } from "./action"
import serviceURL from "../../../../helper/serviceURL"
import { prepareAssessmentDataHelper, prepareLinkAccordData } from "../../../../helper/Helper"
import styles from "./RowDetails.module.scss"
import QuesAnsAssessment from "../../../../components/queAnsAsssessment/QuesAnsAssessment"
import _ from "lodash"
import { ASSESS_PROGRESS_STATUS, STATUS, USER_ROLE } from "../../../../helper/constants"
import { progressStatus } from "../../../../helper/interface"
import AssessmentSummary from "../../../../components/assessmentSummary/AssessmentSummary"
import LinkedInvCard from "../../../../components/linkedInvCard/LinkedInvCard"
import TagStatusCategoryIndicator from "../../../../helper/TagStatusCategoryIndicator"
import { getOrgByUserRed } from "app/reducer"
import useAxiosInterceptors from "../../../../hooks/useAxiosInterceptors"
import { getLocalDate } from "../../../../helper/Helper"
import AssessmentPdf from "../../../../components/pdf/assessmentPdf"
import RelaunchAssessment from "../createAssessment/RelaunchAssessment"
import appConfig from "app.config"

const mapDispatchToProps = (dispatch: any) => ({
  getAssessmentByIdDispatcher: (id: string, head: object, orgId: number) =>
    dispatch(getAssessmentByIdDispatcher(id, head, orgId)),
  clearAssessmentByIdDispatcher: (invAssetRes: object) => dispatch(clearAssessmentByIdDispatcher(invAssetRes)),
  getInventoryByAssessIdDispatcher: (type: string, id: string, head: object, orgId: number) =>
    dispatch(getInventoryByAssessIdDispatcher(type, id, head, orgId))
})

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    assessRowDetails: state.assessmentRowDetailsRed.data,
    inventoryByAssessDetails: state.inventoryDetailsViaAssessRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    getOrgByUserRed: state.getOrgByUserRed.data
  }
}

export interface RowDetailsModalProps {
  clickedRowData?: {
    id: string
    name: string
    description: string
    inventoryId: string
    inventoryType: any
    categories: any
    status: boolean
  }
  open: boolean
  onClose: () => void
  getAssessmentByIdDispatcher?: (id: string, head: object, orgId: number) => Promise<any>
  getInventoryByAssessIdDispatcher?: (type: string, id: string, head: object, orgId: number) => Promise<any>
  clearAssessmentByIdDispatcher?: (invAssetRes: object) => Promise<any>
  selectedSideOrg?: {
    id: number
    name: string
  }
  getOrgByUserRed: any
  base64Email?: string
  assessRowDetails?: any
  inventoryDetails?: any
  showApproveToast?: (val: boolean) => void
  closeSideModal?: () => void
}

interface childRowProps {
  id: string
  name: string
}

const initialAccordArrState: AccordItem[] = []

const initialStateChildRow = {
  id: "",
  name: "",
  inventoryAttributes: { categories: [] }
}
const RowDetails = memo(
  ({
    open,
    onClose,
    clickedRowData,
    getAssessmentByIdDispatcher,
    getInventoryByAssessIdDispatcher,
    clearAssessmentByIdDispatcher,
    base64Email,
    assessRowDetails,
    selectedSideOrg,
    getOrgByUserRed,
    showApproveToast,
    closeSideModal
  }: RowDetailsModalProps) => {
    const initialState = { id: 0, name: "" }
    const [isLoading, setIsLoading] = useState(false)
    const [isToShowBanner, setIsToShowBanner] = useState(false)
    const [isToShowInvBanner, setIsToShowInvBanner] = useState(false)
    const [inventoryByAssessDetails, setInventoryByAssessDetails] = useState(initialStateChildRow)
    const [inventoryDetails, setInventoryDetails] = useState(initialStateChildRow)
    const [openAccordionId, setOpenAccordionId] = useState<string | null>(null)
    const [currentTab, setCurrentTab] = useState("AssessmentDetails")
    const [selectedInventory, setSelectedInventory] = useState(initialState)
    const [accordAssessObj, setAccordAssessObj] = useState<AssessAccordItem>({
      assessmentId: "1",
      assessmentName: "",
      details: [],
      inventoryName: null,
      isAllQnNonEmpty: false
    })
    const [showLinkChildAccSpin, setShowLinkChildAccSpin] = useState(false)
    const [accordLinkArr, setLinkAccordArr] = useState<AccordItem[]>(initialAccordArrState)
    const [invAssetRes, setInvAssetRes] = useState({ asessDetails: null, invDetails: null, hasError: false })
    const {
      osContext,
      osApi: { getAccessToken }
    } = useOs()
    const userEmail: string = osContext.userDetails.email
    const { axiosInstance } = useAxiosInterceptors()
    const headers = {
      accept: "*/*",
      Authorization: "Bearer " + getAccessToken()
    }
    const [catAttributes, setCatAttributes] = useState<any[]>([])
    const parentRef = useRef<HTMLDivElement>(null)
    const [progressValue, setProgressValue] = useState(0)
    const [loadAgain, setLoadAgain] = useState({ isLoad: false, assessId: "" })
    const [reviewerCommentLoaded, setReviewerCommentLoaded] = useState(null)
    const [beforeSummaryLoad, setBeforeSummaryLoad] = useState(false)
    const [relaunchModal, setRelaunchModal] = useState(false)
    const progressCalculator = (list: progressStatus[], val: string) => {
      for (const [key, listVal] of Object.entries(list)) {
        if (listVal["name"] === val) {
          return listVal["value"]
        }
      }
      return 0
    }
    const executeCallback = useCallback(
      (val: any) => {
        setReviewerCommentLoaded(val)
      },
      [reviewerCommentLoaded]
    )
    useEffect(() => {
      if (assessRowDetails && assessRowDetails.status) {
        setProgressValue(progressCalculator(ASSESS_PROGRESS_STATUS, assessRowDetails.status))
      }
    }, [assessRowDetails])

    useLayoutEffect(() => {
      fetchAssessmentById()
      return () => {
        clearAssessmentByIdDispatcher &&
          clearAssessmentByIdDispatcher(invAssetRes)
            .then(res => {
              console.log("clearing row details modal")
            })
            .catch(err => {
              console.log(err)
            })
      }
    }, [])

    const fetchAssessmentById = () => {
      if (clickedRowData && selectedSideOrg) {
        setIsLoading(true)
        getAssessmentByIdDispatcher &&
          getAssessmentByIdDispatcher(clickedRowData.id, headers, selectedSideOrg.id)
            .then(res => {
              console.log("result by assessment  id>>>>", res)
              setIsLoading(false)
              prepareAssessmentData(res)
            })
            .catch(err => {
              console.log(err)
              setIsLoading(false)
              setIsToShowBanner(true)
            })
      }
    }
    const fetchInventoryByAssessId = () => {
      if (clickedRowData && selectedSideOrg && assessRowDetails && assessRowDetails.inventoryId) {
        setIsLoading(true)
        getInventoryByAssessIdDispatcher &&
          getInventoryByAssessIdDispatcher(
            assessRowDetails.inventoryType.name,
            assessRowDetails.inventoryId,
            headers,
            selectedSideOrg.id
          )
            .then(res => {
              if (res) {
                console.log("result by inventory id>>>>", res)
                setInventoryByAssessDetails(res)
                setIsLoading(false)
                makeLinkAccordData(res)
              }
            })
            .catch(err => {
              console.log(err)
              setIsLoading(false)
              setIsToShowInvBanner(true)
            })
      }
    }

    const prepareAssessmentData = (data: any) => {
      const _assessment = prepareAssessmentDataHelper(data, getOrgByUserRed.groups, selectedSideOrg, getOrgByUserRed)
      setAccordAssessObj(_assessment)
    }
    const makeLinkAccordData = (data: { linkedInventories: object[] }) => {
      const resOfPrepareLinkAccordData: any = prepareLinkAccordData(data)
      setLinkAccordArr(resOfPrepareLinkAccordData)
    }

    const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
      setCurrentTab(event.detail.value)
    }
    const handleAccord = (inventoryID: string, type: string) => {
      setOpenAccordionId(inventoryID)
      if (openAccordionId !== inventoryID && selectedSideOrg) {
        setShowLinkChildAccSpin(true)
        const apiUrl =
          serviceURL.pgpBaseAPI + "/api/inventory/" + type + "/" + inventoryID + "?orgId=" + selectedSideOrg.id
        axiosInstance
          .get(apiUrl, {
            headers: headers
          })
          .then(res => {
            setInventoryDetails(res.data)
            setShowLinkChildAccSpin(false)
          })
          .catch(error => {
            console.log(error)
            setShowLinkChildAccSpin(false)
          })
      }
    }

    const renderInventoryDetails = () => {
      return (
        <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid item xs={12} sm={12} md={12}>
            <WppBanner id="invBanner" type="information" show={isToShowInvBanner} className={styles.banner}>
              Unable to fetch information. Please refresh or try after some time.
            </WppBanner>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <WppTypography
              slot="header"
              type="m-strong"
              tag="p"
              className={`${
                isLoading ? styles.wppTypographySubHeader + " " + styles.loading : styles.wppTypographySubHeader
              }`}
            >
              Inventory Attributes
            </WppTypography>
            {renderInventoryAttrDetails()}
          </Grid>

          {/* <Grid item xs={12} sm={6} md={6}>
            <WppTypography
              slot="header"
              type="m-strong"
              tag="p"
              className={`${
                isLoading ? styles.wppTypographySubHeader + " " + styles.loading : styles.wppTypographySubHeader
              }`}
            >
              Linked Inventories
            </WppTypography>
            {renderLinkedInventories()}
          </Grid> */}
        </Grid>
      )
    }
    useEffect(() => {
      if (
        currentTab &&
        currentTab === "InventoryDetails" &&
        _.isEqual(inventoryByAssessDetails, initialStateChildRow)
      ) {
        fetchInventoryByAssessId()
      }
    }, [currentTab, inventoryByAssessDetails])
    const renderInventoryAttrDetails = () => {
      return (
        <>
          {inventoryByAssessDetails?.inventoryAttributes?.categories?.map((cat: any, parentIdx: number) => {
            return (
              <WppAccordion key={`accordion-${cat.id}`} withDivider={false} className={styles.accordStyle} size="m">
                <WppTypography type="m-strong" slot="header" className={styles.accordHeader} key={`header-${cat.id}`}>
                  {cat.name}
                </WppTypography>
                <div key={`typography-${cat.id}`} className={styles.secInventoryDetails}>
                  {cat.attributes.map(
                    (
                      attr: {
                        businessName: string
                        responseValue: string
                      },
                      idx: number
                    ) => (
                      <>
                        {
                          <div key={`row-${idx}`}>
                            <span key={`key-${idx}`} className={styles.key}>
                              {attr?.businessName} :
                            </span>
                            <span key={`val-${idx}`} className={styles.childValue}>
                              {attr?.responseValue?.replace(/\|/g, " | ") || "N/A"}
                            </span>
                          </div>
                        }
                      </>
                    )
                  )}
                </div>
              </WppAccordion>
            )
          })}
        </>
      )
    }

    const handleRelaunchAssessment = () => {
      setRelaunchModal(true)
    }

    const isEligibleForRelaunch = () => {
      if(appConfig.CUTOFF_DATE !== 'NA'){
        const launchDate = new Date(assessRowDetails?.createdAt);
        const cutOffDate = new Date(appConfig.CUTOFF_DATE);
        const isBefore = launchDate < cutOffDate
        return assessRowDetails?.status === STATUS.RESPONSE_PENDING && isBefore
      } else {
        return assessRowDetails?.status === STATUS.RESPONSE_PENDING
      }
     
    }

    const hasRequiredPermission = (permission: string[]) => {
      return getOrgByUserRed?.groups?.some((item: any) => permission.includes(item.name));
    }

    return (
      <>
        <WppSideModal open={open} size={"2xl"} onWppSideModalClose={onClose}>
          <h3 slot="header" className={styles.assessNameProgressContainer}>
            <span>{clickedRowData && clickedRowData.name}</span>
          </h3>
          <div slot="body" style={{ marginTop: "1.5rem" }}>
            {assessRowDetails.status && (
              <div className={styles.progressContainer}>
                <div>
                  <div>Review process {progressValue * 100 + "%"}</div>
                  <progress className={styles.progressBar} value={progressValue} />
                </div>
                {isEligibleForRelaunch() && (
                  <div>
                    <WppButton
                      className={styles.customBtnSecondary}
                      variant="secondary"
                      size="s"
                      onClick={handleRelaunchAssessment}
                    >
                      <WppIconAdd slot="icon-start" className={styles.customIcon} />
                      Relaunch Assessment
                    </WppButton>
                  </div>
                )}
              </div>
            )}
            {isLoading && (
              <div style={{ marginBottom: "1rem" }}>
                <WppProgressIndicator className={styles.customLoader} variant="bar" isShowPercentage={false} />
              </div>
            )}

            <div style={{ marginBottom: "1rem" }}>
              <WppBanner id="banner" type="information" show={isToShowBanner} className={styles.banner}>
                Unable to fetch information. Please refresh or try after some time.
              </WppBanner>
            </div>
            {hasRequiredPermission([USER_ROLE.SAD, USER_ROLE.DPM]) && <AssessmentPdf data={assessRowDetails} isLoading={isLoading} />}
            {assessRowDetails && (
              <Container maxWidth="xl">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={styles.secInventoryDetailsTop}>
                      <div className={styles.headerContainer}>
                        <div className={styles.leftContainer}>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Name: </span>
                            <span>{assessRowDetails && assessRowDetails.name}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Description: </span>
                            <span className={styles.value}>
                              {assessRowDetails && assessRowDetails.description ? (
                                <WppTag
                                  label={assessRowDetails.description}
                                  className={styles.tag}
                                  maxLabelLength={40}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Respondents: </span>
                            {assessRowDetails?.respondents && assessRowDetails?.respondents.length ? (
                              <span className={styles.respondedContainer}>
                                <WppTag
                                  label={assessRowDetails?.respondents
                                    ?.map((m: { email: string }, index: number) => m?.email)
                                    .join(", ")}
                                  className={styles.tag}
                                  maxLabelLength={40}
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Status: </span>
                            {assessRowDetails.status && (
                              <TagStatusCategoryIndicator params={{ value: assessRowDetails?.status }} />
                            )}
                          </div>
                        </div>
                        <div className={styles.rightContainer}>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Created By: </span>
                            <span>{assessRowDetails && assessRowDetails?.createdBy}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Created At: </span>
                            <span>{assessRowDetails?.createdAt && getLocalDate(assessRowDetails?.createdAt)}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Inventory Type: </span>
                            <span>{assessRowDetails && assessRowDetails.inventoryType?.businessName}</span>
                          </div>
                          <div className={styles.headerBox}>
                            <span className={styles.key}>Last Activity Date: </span>
                            <span>{assessRowDetails?.modifiedAt && getLocalDate(assessRowDetails?.modifiedAt)}</span>
                          </div>
                        </div>
                      </div>
                      <Grid className={styles.flowChart} item xs={12} sm={12} md={12}>
                        <AssessmentSummary
                          assessRowDetails={assessRowDetails}
                          callBackDataLoaded={executeCallback}
                          assessmentRiskCountDetails={accordAssessObj}
                          base64Email={base64Email}
                          reviewerCommentLoaded={reviewerCommentLoaded}
                        />
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} paddingTop={"2rem !important"} paddingBottom={"5rem !important"}>
                    <div className={styles.tabSection}>
                      <div className={styles.assessmentHeading}>
                        <WppTypography type="s-strong">Assessment Details</WppTypography>
                      </div>
                      <WppTypography className={styles.tabContent} id="queAndAnswers">
                        {!isLoading && (
                          <QuesAnsAssessment
                            assessment={accordAssessObj}
                            showApproveToast={showApproveToast}
                            closeSideModal={closeSideModal}
                            prepareAssessmentData={prepareAssessmentData}
                          />
                        )}
                      </WppTypography>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            )}
          </div>
        </WppSideModal>
        {relaunchModal && (
          <RelaunchAssessment relaunchModal={relaunchModal} onClose={onClose} assessRowDetails={assessRowDetails} />
        )}
      </>
    )
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(RowDetails)
