/**************************************
 configuration for production
 please do not make any changes here
 **************************************/

const appConfig = {
    UI_VERSION: 'main-1.0.9',
    RA_HOST_URL: 'https://int-ra.choreograph.com',
    DPS_HOST_URL: 'https://int-dps.choreograph.com',
    RA_PASSWORD: 'password',
    DPS_TOKEN: 'YWRtaW46QWRtaW4xMjM0JA==',
    NPROD: 'NPROD',
    CUTOFF_DATE: 'NA'
}

/*******************************************
 configuration for local
 make changes here to run the app locally
 *******************************************/

/*const appConfig = {
    UI_VERSION: 'local-1.0.0',
    RA_HOST_URL: 'https://dev-ra.choreograph.com',
    DPS_HOST_URL: 'https://dev-dps.choreograph.com',
    RA_PASSWORD: 'password',
    DPS_TOKEN: '',
    NPROD: 'NPROD',
    CUTOFF_DATE: '2024-06-30T00:00:00'
}*/



export default appConfig;