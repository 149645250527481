import {
  WppButton,
  WppIconCross,
  WppIconSearch,
  WppInput,
  WppLabel,
  WppListItem,
  WppSelect
} from "@wppopen/components-library-react"
import React, { useEffect, useLayoutEffect, useState } from "react"
import _ from "lodash"
import { Grid, Box } from "@mui/material"
import { searchCompProps } from "./interface"
import FilterComp from "./FilterComp"
import { searchData } from "../../helper/interface"
import styles from "./SearchComp.module.scss"

const SearchComp = React.memo(({ searchList, filterQuery, initialGridList, type }: searchCompProps) => {
  const copyList = _.cloneDeep(searchList)
  const searchableList = copyList && (copyList.length > 10 ? copyList.slice(0, 10) : copyList) // Restrict to max five search fields
  const [filterToggle, setFilterToggle] = useState(false)
  const initialData = [{ id: 1, name: "", apiName: "", value: "" }]
  const [isSearchDisable, setIsSearchDisable] = useState(true)
  const isSingleSearch: boolean = searchableList.length === 1
  const [selectedColumn, setSelectedColumn] = useState([])
  const [singleColumn, setSingleColumn] = useState("")
  const [inputFields, setInputFields] = useState<searchData[]>(initialData)
  const [storeVals, setStoreVals] = useState<any>([])
  const [troubleShoot, setTroubleshoot] = useState(false)

  const filterOpenHandler = () => {
    setFilterToggle(!filterToggle)
  }
  const filterCloseHandler = () => {
    setSelectedColumn([])
    setFilterToggle(false)
    setInputFields([])
    setStoreVals([])
    filterQuery("reset", [])
  }
  const columnSelectHandler = (event: any) => {
    if (event.target.value && event.target.value.length > 0) {
      setSelectedColumn(event.target.value)
    } else {
      setSelectedColumn([])
      setFilterToggle(false)
      filterQuery("reset", [])
    }
  }
  const searchSingleColumnHandler = (e: any, item: searchData) => {
    const itemm = { ...item, value: singleColumn }
    filterQuery(`filterBy=${item["apiName"]}&filterValue=${encodeURIComponent(singleColumn)}`, itemm)
  }
  const singleColumnCloseHandler = () => {
    setSingleColumn("")
    filterQuery("reset", {})
  }
  const singleSelectColumnInputHandler = (inputVal: any) => {
    if (inputVal.trim().length) {
      setSingleColumn(inputVal)
    } else {
      singleColumnCloseHandler()
    }
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLWppInputElement>, item: searchData) => {
    if (e.key === "Enter") {
      searchSingleColumnHandler(e, item)
    }
  }

  const searchSingleColumn = (item: searchData) => {
    return (
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} spacing={{ xs: 2, md: 2, sm: 2 }} justifyContent="flex-end">
        <Grid item xs={12} sm={6} md={6} marginRight="2.5rem">
          <WppInput
            name={item.apiName}
            key={item.id}
            placeholder={item.name}
            value={singleColumn || ""}
            size="s"
            onWppChange={e => singleSelectColumnInputHandler(e.target.value)}
            onKeyDown={e => handleKeyDown(e, item)}
          >
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
            {singleColumn && singleColumn.length > 0 && (
              <WppIconCross slot="icon-end" aria-label="Clear icon" onClick={e => singleColumnCloseHandler()} />
            )}
          </WppInput>
        </Grid>
        <Grid item xs={12} sm={4} md={4} marginRight="2.5rem">
          <WppButton
            variant={"secondary"}
            className={styles.customBtnSecondary}
            size="s"
            disabled={singleColumn.length <= 0}
            onClick={e => searchSingleColumnHandler(e, item)}
            style={{ marginRight: "2.5rem" }}
          >
            Search
          </WppButton>
        </Grid>
      </Grid>
    )
  }

  const inputHandler = (idx: number, e: any) => {
    let data = initialData
    if (inputFields.length) {
      data = [...inputFields]
      for (const i of data) {
        if (i["id"] === idx) {
          i["value"] = e.detail.value
        }
      }
    }
    setStoreVals(data)
    setTimeout(() => {
      const res = inputFields.filter((item: searchData) => {
        return item.value.length > 0
      })
      if (res.length) {
        setIsSearchDisable(false)
      } else {
        setIsSearchDisable(true)
      }
    }, 300)
  }
  const inputValueHolder = (idx: number): any | string => {
    if (inputFields.length) {
      for (const [index, val] of inputFields.entries()) {
        while (index === idx) {
          if (val["value"].length > 0) {
            return val["value"]
          } else {
            return ""
          }
        }
      }
    }
  }

  const searchHandler = (e: any, resultList: searchData[]) => {
    let copyList: searchData[] = _.cloneDeep(resultList)
    let searchResult
    searchResult = copyList.filter((item: searchData) => {
      return item["value"].length > 0
    })
    if (type === "Vendor" && searchResult.length) {
      let search = ""
      searchResult.forEach((i, index) => {
        search += `${i.apiName}=${i.value}${index + 1 === searchResult.length ? "" : "&"}`
      })
      filterQuery(search, searchResult)
      return search;
    }
    if (searchResult.length === 1) {
      filterQuery(
        `filterBy=${searchResult[0]["apiName"]}&filterValue=${encodeURIComponent(searchResult[0]["value"])}`,
        searchResult
      )
    } else if (searchResult.length === 2) {
      filterQuery(
        `filterBy=${searchResult[0]["apiName"]}&filterValue=${encodeURIComponent(searchResult[0]["value"])}&filterBy1=${
          searchResult[1]["apiName"]
        }&filterValue1=${encodeURIComponent(searchResult[1]["value"])}`,
        searchResult
      )
    } else if (searchResult.length === 3) {
      filterQuery(
        `filterBy=${searchResult[0]["apiName"]}&filterValue=${encodeURIComponent(searchResult[0]["value"])}&filterBy1=${
          searchResult[1]["apiName"]
        }&filterValue1=${encodeURIComponent(searchResult[1]["value"])}&filterBy2=${
          searchResult[2]["apiName"]
        }&filterValue2=${encodeURIComponent(searchResult[2]["value"])}`,
        searchResult
      )
    } else if (searchResult.length === 4) {
      filterQuery(
        `filterBy=${searchResult[0]["apiName"]}&filterValue=${encodeURIComponent(searchResult[0]["value"])}&filterBy1=${
          searchResult[1]["apiName"]
        }&filterValue1=${encodeURIComponent(searchResult[1]["value"])}&filterBy2=${
          searchResult[2]["apiName"]
        }&filterValue3=${encodeURIComponent(searchResult[3]["value"])}&filterBy3=${
          searchResult[3]["apiName"]
        }&filterValue3=${encodeURIComponent(searchResult[3]["value"])}`,
        searchResult
      )
    } else if (searchResult.length === 5) {
      filterQuery(
        `filterBy=${searchResult[0]["apiName"]}&filterValue=${encodeURIComponent(searchResult[0]["value"])}&filterBy1=${
          searchResult[1]["apiName"]
        }&filterValue1=${encodeURIComponent(searchResult[1]["value"])}&filterBy2=${
          searchResult[2]["apiName"]
        }&filterValue3=${encodeURIComponent(searchResult[3]["value"])}&filterBy3=${
          searchResult[3]["apiName"]
        }&filterValue3=${encodeURIComponent(searchResult[3]["value"])}&filterBy4=${
          searchResult[4]["apiName"]
        }&filterValue4=${encodeURIComponent(searchResult[4]["value"])}`,
        searchResult
      )
    }
  }
  const searchInput = (list: any) => {
    if (list.length > 0) {
      return (
        <Box className={styles.searchBox}>
          <Grid container direction="column" justifyContent="space-between" rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container direction="row" spacing={2} rowSpacing={1}>
                {list.map((item: any, idx: number) => {
                  return (
                    <Grid item xs={3} sm={3} md={3} spacing={1}>
                      <span className={styles.label}>{item.name}</span>
                      <WppInput
                        name={item.apiName}
                        key={item.id}
                        value={inputValueHolder(idx)}
                        size="s"
                        onWppChange={e => inputHandler(item.id, e)}
                        style={{ marginBottom: "1rem" }}
                        placeholder={item.placeholder || ""}
                        message={item.apiName === "status" ? item.placeholder || "" : ""}
                      >
                        <WppIconSearch slot="icon-start" aria-label="Search icon" />
                      </WppInput>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {search()}
            </Grid>
          </Grid>
        </Box>
      )
    }
    return null
  }
  const selectColumn = (columnList: any) => {
    return (
      <>
        <Grid container columns={{ xs: 12, sm: 12, md: 12 }} justifyContent="flex-end">
          <Grid item xs={12} sm={3} md={3}>
            <WppSelect
              onWppChange={columnSelectHandler}
              type="multiple"
              placeholder="Filter By Column"
              size="s"
              withFolder={true}
              dropdownConfig={{ trigger: "focus click" }}
              autoFocus={true}
              maxItemsToDisplay={columnList?.length?.length}
              value={selectedColumn}
              className={styles.listSelect}
            >
              {columnList.map((column: searchData) => {
                return (
                  <WppListItem key={column.id} value={column}>
                    <p slot="label">{column.name}</p>
                  </WppListItem>
                )
              })}
            </WppSelect>
          </Grid>
          {/* <Grid container direction="row" xs={12} sm={12} md={12} className={styles.searchColumn}> */}

          {/* </Grid> */}
        </Grid>
        {!troubleShoot && (
          <Grid container columns={{ xs: 12, sm: 12, md: 12 }} className={styles.searchColumn}>
            {/* <Grid > */}
            {searchInput(inputFields)}
            {/* </Grid> */}
          </Grid>
        )}
      </>
    )
  }
  const search = () => {
    return (
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item xs="auto">
          <WppButton
            className={styles.customBtnSecondary}
            size="s"
            disabled={isSearchDisable}
            onClick={e => searchHandler(e, inputFields)}
          >
            Search
          </WppButton>
        </Grid>
        <Grid item xs="auto">
          <FilterComp counter={0} filterClick={filterCloseHandler} searchableList={searchableList} isShowTxt={false} />
        </Grid>
      </Grid>
    )
  }
  const filterRenderer = () => {
    if (searchableList && searchableList.length) {
      if (isSingleSearch) {
        return searchSingleColumn(searchableList[0])
      } else {
        return selectColumn(searchableList)
      }
    }
    return null
  }
  useEffect(() => {
    let injectedFields: searchData[] = []
    let troubleFields
    const processDynamicSeletion = (list: any[]) => {
      for (const troubleSelectVal of list.values()) {
        if (storeVals && storeVals.length) {
          for (const storeVal of storeVals.values()) {
            if (troubleSelectVal["id"] === storeVal["id"]) {
              injectedFields.push(storeVal)
            }
          }
        }
        if (injectedFields.length > 0) {
          const copySelectedColumn: searchData[] = _.cloneDeep(selectedColumn)
          for (const [selectedIdx, selectedVal] of selectedColumn.entries()) {
            for (const inputVal of injectedFields) {
              if (inputVal["id"] === selectedVal["id"]) {
                copySelectedColumn.splice(selectedIdx, 1, inputVal)
              }
            }
          }
          setInputFields(copySelectedColumn)
        } else {
          setInputFields(_.cloneDeep(selectedColumn))
        }
      }
    }
    //multi select WPP Comp returns strange 3 values, handler starts
    if (selectedColumn.length && selectedColumn.length > searchableList.length) {
      setTroubleshoot(true)
      troubleFields = selectedColumn.slice(0, searchableList.length)
      processDynamicSeletion(troubleFields)
      //multi select WPP Comp returns strange 3 values, handler ends
    } else if (selectedColumn.length) {
      processDynamicSeletion(selectedColumn)
    } else {
      setInputFields(_.cloneDeep(selectedColumn))
    }
  }, [selectedColumn])
  useLayoutEffect(() => {
    if (initialGridList && initialGridList.length === 0) {
      return
    }
  }, [initialGridList])
  return (
    <React.Fragment>
      {initialGridList.length ? (
        <Grid item xs={12} sm={12} md={12} display="flex" alignItems="flex-end" flexDirection="column">
          {searchableList && searchableList.length && searchableList.length > 1 && !filterToggle && (
            <FilterComp
              counter={searchableList.length}
              filterClick={filterOpenHandler}
              searchableList={searchableList}
              isShowTxt={true}
            />
          )}
          {filterToggle && filterRenderer()}
          {searchableList && searchableList.length === 1 && filterRenderer()}
        </Grid>
      ) : null}
    </React.Fragment>
  )
})

export default SearchComp
