import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { WppInput, WppIconAdd, WppIconRemove, WppButton } from "@wppopen/components-library-react"
import styles from "./EmailRespondent.module.scss"
import { InputChangeEventDetail } from "@wppopen/components-library"
import { WppInputCustomEvent } from "@wppopen/components-library/dist/types/components"

interface Respondent {
  id: number
  fullname: string
  email: string
}
interface EmailRespondentProps {
  value: Array<{ id: number; fullname: string; email: string }>
  setValue: (value: Respondent[]) => void
  validationErrors: { error: string; name: string }
}

const EmailRespondent = ({ value, setValue, validationErrors }: EmailRespondentProps) => {
  const [respondents, setRespondents] = useState<Respondent[]>([{ id: Date.now(), fullname: "", email: "" }])

  useEffect(() => {
    setRespondents(value)
  }, [value])

  const handleEmailChange = (id: number, event: WppInputCustomEvent<InputChangeEventDetail>) => {
    const { name, value } = event.target
    setRespondents(prevRespondents => {
      const updatedRespondents = prevRespondents.map(respondent =>
        respondent.id === id ? { ...respondent, [name as keyof Respondent]: value } : respondent
      )
      setValue(updatedRespondents)
      return updatedRespondents
    })
  }

  const addRespondent = () => {
    setRespondents(prevRespondents => {
      const newRespondent = { id: Date.now(), fullname: "", email: "" }
      const updatedRespondents = [...prevRespondents, newRespondent]
      setValue(updatedRespondents)
      return updatedRespondents
    })
  }

  const removeRespondent = (id: number) => {
    setRespondents(prevRespondents => {
      const updatedRespondents = prevRespondents.filter(respondent => respondent.id !== id)
      setValue(updatedRespondents)
      return updatedRespondents
    })
  }

  const getValidationError = (id: number, fieldName: string): string => {
    const error = validationErrors?.find(item => item.name === `${fieldName}-${id}`)
    return error ? error.error : ""
  }
  return (
    <>
      {respondents.map((respondent, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={5} md={5}>
            <WppInput
              name="fullname"
              labelConfig={{ text: "Name of Respondent" }}
              placeholder="Name Of Respondent"
              size="s"
              required={true}
              value={respondent.fullname}
              onWppChange={event => handleEmailChange(respondent.id, event)}
              message={getValidationError(respondent.id, "fullname")}
              messageType={getValidationError(respondent.id, "fullname") ? "error" : undefined}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <WppInput
              name="email"
              labelConfig={{ text: "Respondent Email" }}
              placeholder="Respondent Email"
              required={true}
              value={respondent.email}
              size="s"
              onWppChange={event => handleEmailChange(respondent.id, event)}
              message={getValidationError(respondent.id, "email")}
              messageType={getValidationError(respondent.id, "email") ? "error" : undefined}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            className={
              getValidationError(respondent.id, "fullname") || getValidationError(respondent.id, "email")
                ? styles.btnBox
                : styles.btnBoxError
            }
          >
            <WppButton variant="secondary"  className={styles.customBtnSecondary} size="s" onClick={addRespondent}>
              <WppIconAdd size="s" slot="icon-end" aria-label="Add icon" />
            </WppButton>
            <WppButton
              variant="secondary"
              size="s"
              className={styles.customBtnSecondary}
              onClick={() => removeRespondent(respondent.id)}
              disabled={respondents?.length === 1}
            >
              <WppIconRemove size="s" slot="icon-end" aria-label="Remove icon" />
            </WppButton>
          </Grid>
        </React.Fragment>
      ))}
    </>
  )
}

export default EmailRespondent
