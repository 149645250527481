import axios from 'axios';
import {serviceURL} from '../../../../../helper/serviceURL';
import {
    SELECTED_TYPE, SELECTED_REGION, SELECTED_MARKET, SELECTED_AGENCY,
    GET_REG_LIST, GET_MARKET_LIST, GET_AGENCY_LIST, SELECTED_INVENTORY, GET_INV_LIST
} from './types';

export const selectedType = (type: object) => ({
    type: SELECTED_TYPE,
    payload: type
});

export const selectedTypeDispatcher = (type: object) => (dispatch: any) => {
    dispatch(selectedType(type));
};

export const selectedInv = (inv: object) => ({
    type: SELECTED_INVENTORY,
    payload: inv
});

export const selectedInvDispatcher = (inv: object) => (dispatch: any) => {
    dispatch(selectedInv(inv));
};

export const SelectedRegion = (reg: object) => ({
    type: SELECTED_REGION,
    payload: reg
});

export const selectedRegionDispatcher = (reg: object) => (dispatch: any) => {
    dispatch(SelectedRegion(reg));
};

export const SelectedMarket = (market: object) => ({
    type: SELECTED_MARKET,
    payload: market
});

export const selectedMarketDispatcher = (market: object) => (dispatch: any) => {
    dispatch(SelectedMarket(market));
};

export const SelectedAgency = (agency: object) => ({
    type: SELECTED_AGENCY,
    payload: agency
});

export const selectedAgencyDispatcher = (agency: object) => (dispatch: any) => {
    dispatch(SelectedAgency(agency));
};

export const getInvList = (invList: any) => ({
    type: GET_INV_LIST,
    payload: invList
});

export const getInvListDispatcher = (type: string, head: object, orgId: number) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/inventories?type=' + type +
        '&page=0&size=10' + '&orgId=' + orgId;
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: head
        }).then((res: any) => {
            dispatch(getInvList(res.data.content));
            resolve(res.data.content);
        }).catch((error) => reject(error));
    });
};

export const getRegList = (regList: any) => ({
    type: GET_REG_LIST,
    payload: regList
});

export const getRegListDispatcher = (orgId: number, head: object) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/' + orgId + '/regions';
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: head
        }).then((res: any) => {
            dispatch(getRegList(res.data));
            resolve(res);
        }).catch((error) => reject(error));
    });
};

export const getMarketList = (marketList: any) => ({
    type: GET_MARKET_LIST,
    payload: marketList
});

export const getMarketListDispatcher = (regId: number, head: object) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/regions/' + regId + '/markets';
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: head
        }).then((res: any) => {
            dispatch(getMarketList(res.data));
            resolve(res);
        }).catch((error) => reject(error));
    });
};

export const getAgencyList = (agencyList: any) => ({
    type: GET_AGENCY_LIST,
    payload: agencyList
});

export const getAgencyListDispatcher = (marketId: number, head: object) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/markets/' + marketId + '/agencies';
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: head
        }).then((res: any) => {
            dispatch(getAgencyList(res.data));
            resolve(res);
        }).catch((error) => reject(error));
    });
};
