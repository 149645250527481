import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import SideNavWpp from '../containers/sideNav/SideNavWpp';
import CPPDashboard from "../containers/cppModule/cppDashboard/CPPDashboard";
// import ResolveDsarList from '../containers/cppModule/resolveDsarList/ResolveDsarList';
import FlowChart from "../containers/cppModule/flowchart/flowChart";
import InventoryList from "../containers/dmModule/inventoryList/InventoryList";
import AssessmentList from "../containers/piaModule/assessmentList/AssessmentList";
import {getOrgFail, getOrgReq, getOrgSuc, storeBase64Email, storeAccessToken, setOsContext} from './action';
import {serviceURL} from '../helper/serviceURL';
import {useOs} from '@wppopen/react';
import '@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css';
import styles from 'app/App.module.scss';
import {selectedSideNavOrg} from "../containers/sideNav/action";
import DMDashboard from "../containers/dmModule/dmDashboard/DMDashboard";
import Reports from "../containers/dmModule/reports/Reports";
import CrossBorder from "../containers/dmModule/crossBorder/CrossBorder";
import LandingPage from "../containers/landingPage/LandingPage";
import Templates from "../containers/piaModule/templates/Templates";
import {USER_ROLE} from "../helper/constants";
import {WppProgressIndicator} from "@wppopen/components-library-react";
import UnAuthorized from "../components/unauthorized/UnAuthorized";
import ServiceDown from "../components/serviceDown/ServiceDown";
import VendorsList from "../containers/tprmModule/vendorsList/VendorsList";
import PIADashboard from "../containers/piaModule/piaDashboard/PIADashboard";
import RiskRegister from "../containers/piaModule/riskRegister/RiskRegister";
import TPRMDashboard from '../containers/tprmModule/tprmDashboard/TPRMDashboard';
import LegacyInventories from "../containers/ldModule/legacyInventories/LegacyInventories";
import appConfig from "../app.config";
import useAxiosInterceptors from '../hooks/useAxiosInterceptors'
export const App = () => {
    /**
     * This hook is provided by the utility package for more convenient work with OS context
     * @return osContext
     * - is a subscribe function that returns you the main context object with MicroAppContext data
     * @return osApi
     * - methods to fire actions on the parent OS level
     *
     * Read more about it in the README file
     */
    const { axiosInstance } = useAxiosInterceptors()
    const userRoles = useSelector((state: any) => state.getOrgByUserRed);
    const {osContext, osApi: { getAccessToken }} = useOs();
    console.log('os details', osContext);
    const {email, agency} = osContext.userDetails;
    const dispatch = useDispatch();
    // const [currentLocation, setCurrentLocation] = useState(window.location.pathname);
    useEffect(() => {
        // basic auth username:password
        try {
            dispatch(getOrgReq());
           
            const base64Email = btoa(`${email}:${appConfig.RA_PASSWORD}`);
            const token = getAccessToken();
            const apiUrl = serviceURL.pgpBaseAPI + '/api/access/user/' + email;
            const postData = {token : getAccessToken(),accountId : osContext['permissions'][0]['account_id'].trim() }
            axiosInstance.post(apiUrl, postData, {
                headers: {
                    accept: '*/*',
                }
            }).then((res: any) => {
                dispatch(getOrgSuc(res.data));
                let userAgency = res.data['organizations'].filter((o:any) => o.id === res.data['organizationId'])[0];
                if(osContext?.userDetails){
                    let userDetails = {...osContext.userDetails};
                    userDetails['agency'] = userAgency.name;
                    userDetails['id'] = userAgency.id;
                    dispatch(setOsContext(userDetails));
                }
               
                const initOrg = initialOrg(res.data,userAgency.name);
                dispatch(selectedSideNavOrg(initOrg));
            }).catch((error) => {
                console.log(error)
                dispatch(getOrgFail(error));
            });
            dispatch(storeBase64Email(base64Email));
            dispatch(storeAccessToken(token));
        } catch (error) {
            console.error('Error dispatching action: ', error);
        }
    }, [dispatch, email]);

    const initialOrg = (orgList: { organizations: [{name: string}] }, userAgency : string) => {
        return orgList?.organizations?.length ? orgList?.organizations?.find(org => org?.name?.toLowerCase() === userAgency?.toLowerCase()) || {} : {}
    };

    const routes = [
        {
            path: "/welcome",
            element: <LandingPage/>,
            allowedRoles: [USER_ROLE.SAD],
        },
        {
            path: "/cpp/cpp-dashboard",
            element: <CPPDashboard/>,
            allowedRoles: [USER_ROLE.SAD],
        },
        {
            path: "/cpp/flowchart",
            element: <FlowChart/>,
            allowedRoles: [USER_ROLE.SAD],
        },
        {
            path: "/dm/dm-dashboard",
            element: <DMDashboard/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM],
        },
        {
            path: "/dm/data-mapping",
            element: <InventoryList/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM],
        },
        {
            path: "/dm/reports",
            element: <Reports/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM],
        },
        {
            path: "/dm/cross-border-data-map",
            element: <CrossBorder/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM],
        },
        {
            path: "/pia/pia-dashboard",
            element: <PIADashboard/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM],
        },
        {
            path: "/pia/assessment",
            element: <AssessmentList/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM],
        },
        {
            path: "/pia/assessment/:assessmentId/:orgId/:assessmentName",
            element: <AssessmentList/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM],
        },
        {
            path: "/pia/risk-register",
            element: <RiskRegister />,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM],
        },
        {
            path: "/pia/templates",
            element: <Templates/>,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM],
        },
        {
            path: "/tprm/tprm-dashboard",
            element: <TPRMDashboard />,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.AM, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.SU],
        },
        {
            path: "/tprm/vendors-list",
            element: <VendorsList />,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.AM, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.SU],
        },
        {
            path: "/ld/legacy-inventories",
            element: <LegacyInventories />,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM, USER_ROLE.DPM],
        },
        {
            path: "/unauthorized",
            element: <UnAuthorized />,
            allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM, USER_ROLE.DPM],
        }
    ];

    const checkAuthorization = (allowedRoles: any) => {
        return userRoles?.data?.groups?.some((item: any) =>
            allowedRoles.includes(item.name)
        );
    };


    // let isWelcomePage;
    // useEffect(() => {
    //     isWelcomePage = currentLocation.endsWith('/welcome') || currentLocation.endsWith('/unauthorized');
    //     console.log('app tsx>>>>>', isWelcomePage);
    // }, [currentLocation]);

    if (userRoles.loading) {
        return <WppProgressIndicator variant="bar" className={styles.customLoader} />;
    } else if (userRoles.data) {
        return (
            <BrowserRouter basename={osContext.baseUrl}>
                <div className={styles.container}>
                    <Routes>
                        <Route
                            path="/welcome"
                            element={<LandingPage />}
                        />
                        <Route
                            path="/unauthorized"
                            element={<LandingPage />}
                        />
                        <Route
                            path="/*"
                            element={
                                <div className={styles.adminContainer}>
                                    <nav>
                                        <SideNavWpp />
                                    </nav>
                                    <div className={styles.content}>
                                        <Routes>
                                            {routes.map((route, index) => (
                                                <Route
                                                    key={index}
                                                    path={route.path}
                                                    element={
                                                        route.allowedRoles[0] === USER_ROLE.SAD || checkAuthorization(route.allowedRoles) ? (
                                                            route.element
                                                        ) : (
                                                            <Navigate to="/unauthorized" />
                                                        )
                                                    }
                                                />
                                            ))}
                                            <Route index element={<Navigate to="/welcome" />} />
                                        </Routes>
                                    </div>
                                </div>
                            }
                        />
                    </Routes>
                </div>
            </BrowserRouter>
        );
    } else if (userRoles.error) {
        return <ServiceDown />;
    }

    return null;
}

export default App;
