import React from "react"
import {
  WppTypography,
  WppTag,
  WppCard,
  WppIconMore,
  WppActionButton,
  WppDivider,
  WppListItem,
  WppIconAddCircle
} from "@wppopen/components-library-react"
import styles from "./LandingPage.module.scss"

interface WelcomeMessageProps {
  userData: {
    userEmail: string
    groups: { name: string }[]
    organizationId: number
  }
  agency: string
}

export default function WelcomeMessage({ userData, agency }: WelcomeMessageProps) {
  return (
    <div className={styles.welcomewrapper}>
      <div className={styles?.welcomeSection}>
        <WppTypography type="m-body">
          Welcome, <WppTypography type="m-strong">{userData?.userEmail}</WppTypography>
        </WppTypography>
        <WppTypography type="m-body">
          Organisation {" : "}
          <WppTypography type="m-strong">{agency}</WppTypography>
          {userData?.organizationId === -1 ? (
            <WppTag
              label="No organisation mapped. Please create a support ticket."
              variant="warning"
              maxLabelLength={180}
              className={styles.tag}
            />
          ) : null}
        </WppTypography>{" "}
        <WppTypography type="m-body">
          Permissions {" : "}
          <WppTypography className={styles?.group}>
            {userData?.groups.length > 0 && (
              <>
                {userData?.groups?.map((group, index) => {
                  return (
                    <WppTypography className={styles?.group} key={index} type="m-strong" tag="span">
                      {group?.name}
                      {index < userData.groups.length - 1 ? "," : ""}
                    </WppTypography>
                  )
                })}
              </>
            )}
          </WppTypography>
          {userData?.groups.length === 0 ? (
            <WppTag
              label="No permissions mapped. Please create a support ticket."
              variant="warning"
              maxLabelLength={180}
            />
          ) : null}
        </WppTypography>
      </div>
      <div className={styles?.relasesDetails}>
        <WppCard className={styles.relasesDetailsCard}>
          <WppTypography type="m-strong">V 1.0.9 - what’s new? </WppTypography>
          <div className={styles.listItem}>
            <ul>
              <li>
                <WppTag
                  label="Standard Users can now delete Vendors that they have created themselves. This also deletes the associated assessment."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag
                  label="When deleting a Vendor record, the assessment respondents are now notified via email."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag
                  label="The Vendor table now displays all vendors from across all networks. This makes it easier to find vendors."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Every table column can now be filtered for easier searching." maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Vendor and Assessment records that were created in OneTrust after 30th June have now been imported." maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
            </ul>
            <WppTypography type="m-strong">Updates available from previous release:</WppTypography>
            <ul>
            <li>
                <WppTag
                  label="Respondent can upload multiple attachments for assessment questions."
                  maxLabelLength={60}
                />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag
                  label="Privacy managers can download all uploaded attachments during review process."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag
                  label="Review request email opens assessment card for the vendor to be reviewed."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Agency mapping for EssenceMediacom in Canada." maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Added Choreograph worldwide agency mapping." maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Fixed Choreograph UK agency mapping to Choreograph network." maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Added geosophymedia.com sign-in domain mapping." maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag
                  label="Keyade.com added as a user sign-in domain and mapped to the GroupM network."
                  maxLabelLength={60}
                />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Added agencies to Worldwide for WPP orgs." maxLabelLength={67} />
              </li>
              <li>
                <WppTag label="New mapping for compas-inc.com." maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Assessments can be relaunched through vendor screen." maxLabelLength={67} />
              </li>
              <li>
                <WppTag label="Relaunch Assessment " maxLabelLength={67} />
                <WppDivider></WppDivider>
                <ul>
                  <li>
                    <WppTag label="New mappings for user domains and Australian agencies. " maxLabelLength={67} />
                  </li>
                  <WppDivider></WppDivider>
                  <li>
                    <WppTag
                      label="Existing assessments that are in the Created or Response Pending state can now be relaunched. The vendor will be notified via email and prompted to continue the assessment."
                      maxLabelLength={60}
                    />
                  </li>
                  <WppDivider></WppDivider>
                  <li>
                    <WppTag
                      label="Any previously answered questions will be saved and restored in the relaunched assessment, so the vendor will not need to provide them again. "
                      maxLabelLength={60}
                    />
                  </li>
                  <WppDivider></WppDivider>
                  <li>
                    <WppTag
                      label="You can amend the respondents’ email addresses before relaunching. "
                      maxLabelLength={60}
                    />
                  </li>
                  <WppDivider></WppDivider>
                  <li>
                    <WppTag
                      label="Assessments that were launched in One Trust can now be relaunched in the SDN. "
                      maxLabelLength={60}
                    />
                  </li>
                </ul>
              </li>
              <li>
                <WppTag label="Edit vendor name. " maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
              <li>
                <WppTag label="Delete vendor and assessment. " maxLabelLength={67} />
              </li>
              <WppDivider></WppDivider>
            </ul>
          </div>
        </WppCard>
      </div>
    </div>
  )
}
