// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AZldZ{width:100%;margin-top:1rem;height:85%}.UOHZ9{opacity:.4;margin-bottom:2rem}.XuWHf{color:#000 !important}.XuWHf svg{text-align:left !important}.dmFPa{margin-bottom:2rem}.eZ2He{margin-bottom:2rem}.t_F0e{--wpp-button-secondary-text-color: black;--wpp-button-secondary-border-color: black;--wpp-button-secondary-bg-color-hover: lightgray;--wpp-button-secondary-bg-color-active: lightgray;--wpp-button-secondary-border-color-active: black;--wpp-button-secondary-text-color-active: black;--wpp-button-secondary-border-color-disabled: darkgray;--wpp-button-secondary-text-color-disabled: darkgrey}.xDbZH{color:#000}`, "",{"version":3,"sources":["webpack://./src/containers/piaModule/assessmentList/AssessmentList.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,eAAA,CACA,UAAA,CAGF,OACE,UAAA,CACA,kBAAA,CAGF,OACE,qBAAA,CACA,WACE,0BAAA,CAIJ,OACE,kBAAA,CAGF,OACE,kBAAA,CAGF,OACE,wCAAA,CACA,0CAAA,CACA,gDAAA,CACA,iDAAA,CACA,iDAAA,CACA,+CAAA,CACA,sDAAA,CACA,oDAAA,CAGF,OACE,UAAA","sourcesContent":[".container {\n  width: 100%;\n  margin-top: 1rem;\n  height: 85%;\n}\n\n.loader {\n  opacity: 0.4;\n  margin-bottom: 2rem;\n}\n\n.header {\n  color: black !important;\n  & svg {\n    text-align: left !important;\n  }\n}\n\n.toast {\n  margin-bottom: 2rem;\n}\n\n.loadingArea {\n  margin-bottom: 2rem;\n}\n\n.customBtnSecondary {\n  --wpp-button-secondary-text-color: black;\n  --wpp-button-secondary-border-color: black;\n  --wpp-button-secondary-bg-color-hover: lightgray;\n  --wpp-button-secondary-bg-color-active: lightgray;\n  --wpp-button-secondary-border-color-active: black;\n  --wpp-button-secondary-text-color-active: black;\n  --wpp-button-secondary-border-color-disabled: darkgray;\n  --wpp-button-secondary-text-color-disabled: darkgrey;\n}\n\n.customIcon {\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AZldZ`,
	"loader": `UOHZ9`,
	"header": `XuWHf`,
	"toast": `dmFPa`,
	"loadingArea": `eZ2He`,
	"customBtnSecondary": `t_F0e`,
	"customIcon": `xDbZH`
};
export default ___CSS_LOADER_EXPORT___;
