import React, {useEffect, useState} from 'react';
import {
    WppAccordion,
    WppBanner, WppCard, WppDivider, WppProgressIndicator, WppTag, WppTypography,
} from "@wppopen/components-library-react";
import {useOs} from '@wppopen/react';
import {connect} from 'react-redux';
import styles from './Templates.module.scss';
import {getTemplatesDispatcher} from "../assessmentList/createAssessment/step2/action";
import {TemplatesProps} from "./interface";
import serviceURL from "../../../helper/serviceURL";
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'

const mapStateToProps = (state: any) => {
    return {
        base64Email: state.storeBase64EmailReducer.data,
        templateList: state.caGetTemplateReducer.data,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    getTemplatesDispatcher: (templateList: any) => dispatch(getTemplatesDispatcher(templateList, {name: ''}))
});

export const Templates = ({base64Email, templateList, getTemplatesDispatcher}: TemplatesProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [questionBank, setQuestionBank] = useState<any>([]);
    const [selTemplate, setSelTemplate] = useState<any>();
    const { axiosInstance } = useAxiosInterceptors()
    const {osApi: { getAccessToken }} = useOs();

    const headers = {
        accept: '*/*',
        Authorization: 'Bearer ' + getAccessToken()
    };

    useEffect(() => {
        if (base64Email) {
            setIsLoading(true);
            getTemplatesDispatcher && getTemplatesDispatcher(headers).then(() => {
                setIsLoading(false);
                setShowBanner(false);
            }).catch((error: any) => {
                setIsLoading(false);
                setShowBanner(true);
                console.log(error);
            });
        }
    }, [base64Email]);


    const handleCard = (templateId: string) => {
        console.log('template id>>>', templateId);
        const template = templateList.find((temp: any) => temp.id === templateId);
        setSelTemplate(template);
        const apiUrl = serviceURL.pgpBaseAPI + '/api/templates/template/' + templateId;
        setIsLoading(true);
        axiosInstance
            .get(apiUrl, {
                headers: headers
            })
            .then((res) => {
                prepareMenu(res.data);
                setIsLoading(false);
                setShowBanner(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setShowBanner(true);
            });
    }

    const findQuestions = (questionBank: any, catId: string) => {
        for (const [key, value] of Object.entries(questionBank.questionByCategories)) {
            if (key === catId) {
                return value;
            }
        }
        return [];
    }

    const prepareMenu = (json: any) => {
        let menu: any[];
        menu = json.categories.map((category: any) => {
            let queList = findQuestions(json, category.id);
            return {
                categoryName: category.name,
                questionList: Array.isArray(queList) && queList.map((que: any) => {
                    return {
                        questionName: que.description || 'Not Available',
                        queNo: que.sequence
                    }
                })
            }
        })
        setQuestionBank(menu);
        console.log('Menu >>', menu);
    }

    // const findTemplateName

    return (
        <>
            {isLoading && <WppProgressIndicator className={styles.customLoader} variant="bar"/>}
            <WppBanner id="banner" type="information" show={showBanner}>
                Unable to fetch information. Please refresh or try after some time.
            </WppBanner>
            <div className={styles.container}>
                <div className={styles.cardSection}>
                    {
                        templateList && templateList.map((template: any, idx: number) => (
                            <div key={`template-sec-${idx}`} className={styles.card}>
                                <WppCard
                                    key={`template-${idx}`}
                                    size={'2xl'}
                                    className={styles.cardStyle}
                                    interactive={true}
                                    onWppClick={() => handleCard(template.id)}
                                >
                                    <div key={`name-${idx}`} className={styles.header}>
                                        {template.name}
                                    </div>
                                    <WppDivider/>
                                    <div key={`desc-${idx}`} className={styles.description}>
                                        {template.description}
                                    </div>
                                    <div className={styles.tags}>
                                        <WppTag label={template.status} categoricalColorIndex={7}/>
                                    </div>
                                </WppCard>
                            </div>
                        ))
                    }
                </div>
                {questionBank.length > 0 &&
                    <div className={styles.accordSection}>
                        <div className={styles.accordSecHeader}>
                            Categories with questions in template
                            <span
                                className={styles.templateId}>Template Id: <span>{selTemplate && selTemplate.id}</span></span>
                            <span className={styles.templateId}>
                                <WppTag label={selTemplate?.name}
                                        maxLabelLength={200}
                                        variant="neutral"/></span>
                        </div>
                        {questionBank.map((category: any, idx: number) => (
                            <WppAccordion key={`accord-${idx}`} size="m" withDivider={false}>
                                <WppTypography key={`category-${idx}`} type="m-strong" slot="header">
                                    {category.categoryName}
                                </WppTypography>
                                {category.questionList.length ?
                                    <div>
                                        {category.questionList.map((questions: any) => (
                                            <div key={`question-${questions.queNo}`}
                                                 className={styles.question}>
                                                {questions.queNo + '. ' + questions.questionName}
                                            </div>
                                        ))}
                                    </div> :
                                    <div className={styles.question}>No question available</div>}
                            </WppAccordion>
                        ))}
                    </div>}
            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);