// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dwcdw{justify-content:flex-start;display:flex;align-items:center;gap:10px}.amm9X{justify-content:flex-start;display:flex;align-items:flex-end;gap:10px}.vzbZi{--wpp-button-secondary-text-color: black;--wpp-button-secondary-border-color: black;--wpp-button-secondary-bg-color-hover: lightgray;--wpp-button-secondary-bg-color-active: lightgray;--wpp-button-secondary-border-color-active: black;--wpp-button-secondary-text-color-active: black;--wpp-button-secondary-border-color-disabled: darkgray;--wpp-button-secondary-text-color-disabled: darkgrey}wpp-modal-v2-2-0 .vtPXC::part(wrapper){transform:translate(-50%, -50%)}.f1l38{--wpp-modal-vertical-position-minus-number: 0px}`, "",{"version":3,"sources":["webpack://./src/components/emailRespondent/EmailRespondent.module.scss"],"names":[],"mappings":"AAAA,OACE,0BAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,OACE,0BAAA,CACA,YAAA,CACA,oBAAA,CACA,QAAA,CAGF,OACE,wCAAA,CACA,0CAAA,CACA,gDAAA,CACA,iDAAA,CACA,iDAAA,CACA,+CAAA,CACA,sDAAA,CACA,oDAAA,CAIA,uCACE,+BAAA,CAGJ,OACE,+CAAA","sourcesContent":[".btnBox{\n  justify-content: flex-start;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.btnBoxError{\n  justify-content: flex-start;\n  display: flex;\n  align-items: flex-end;\n  gap: 10px;\n}\n\n.customBtnSecondary {\n  --wpp-button-secondary-text-color: black;\n  --wpp-button-secondary-border-color: black;\n  --wpp-button-secondary-bg-color-hover: lightgray;\n  --wpp-button-secondary-bg-color-active: lightgray;\n  --wpp-button-secondary-border-color-active: black;\n  --wpp-button-secondary-text-color-active: black;\n  --wpp-button-secondary-border-color-disabled: darkgray;\n  --wpp-button-secondary-text-color-disabled: darkgrey;\n}\n\nwpp-modal-v2-2-0{\n  .modal-overlay::part(wrapper){\n    transform: translate(-50%, -50%);\n  }\n}\n.modal{\n  --wpp-modal-vertical-position-minus-number: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBox": `Dwcdw`,
	"btnBoxError": `amm9X`,
	"customBtnSecondary": `vzbZi`,
	"modal-overlay": `vtPXC`,
	"modal": `f1l38`
};
export default ___CSS_LOADER_EXPORT___;
