import React, {forwardRef, memo, useEffect, useImperativeHandle, useState} from "react"
import {parseQuestionBankIds} from "../../helper/Helper"
import _ from "lodash"
import styles from "./QueAnsAssessment.module.scss"
import {WppTextareaInput} from "@wppopen/components-library-react"

interface comment {
   id: string | object,
   comment: string
}
let initialCommentState: comment = {
   id: {},
   comment: ""
}
const CommentSection = memo(forwardRef((props: any, ref) => {
   const [commentRes, setCommentRes] = useState<comment>(initialCommentState)
   useImperativeHandle(ref, () => {
      return {
         commentRes,
         setCommentRes
      }
   }, [commentRes, setCommentRes])
   const commentHandler = () => {
      if (!_.isEqual(commentRes, initialCommentState)) {
         const parseObjRes = parseQuestionBankIds(commentRes.id)
         props.executeComment && props.executeComment({id: parseObjRes, comment: commentRes.comment})
      }
   }
   useEffect(() => {
      commentHandler()
   }, [commentRes])
   return (
      <div className={styles.feedbackContainer}>
         <p>Feedback (Optional):</p>
         <WppTextareaInput
            className={styles.reviewContainerCommentItem}
            name="comment"
            key={`review-comment-txtArea-${props.commentKey}`}
            value={commentRes["comment"]}
            onWppChange={(e) => setCommentRes({id: props.commentKey, comment: e.target.value})}
         />
      </div>
   )
}))
export default CommentSection;