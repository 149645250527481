import React, {useEffect, useState} from 'react';
import {Grid, Container} from '@mui/material';
import {connect} from 'react-redux';
import {
    WppSideModal, WppButton, WppListItem, WppBanner, WppInput, WppTextareaInput,
    WppSelect, WppRadioGroup, WppRadio, WppProgressIndicator, WppSpinner, WppLabel
} from '@wppopen/components-library-react';
import {useOs} from '@wppopen/react';
import {useNavigate} from 'react-router-dom';
import { BUSINESS_TYPES, INVENTORY, SELECT_TYPES, TEXT } from "../../../../helper/constants"
import serviceURL from '../../../../helper/serviceURL';
import {validate} from '../../../../helper/validate';
import styles from './CreateInventory.module.scss';
import {CreateInventoryProps, ValidationError} from '../interface';
import {createInventoryDispatcher} from "./action";
import {selectedTypeDispatcher} from "../../../piaModule/assessmentList/createAssessment/step1/action";
import {getInventoryByIdDispatcher, launchAssessDispatcher} from "../rowDetails/action";
import { convertToTitleCase } from "../../../../helper/Helper"
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import { USER_ROLE } from "../../../../helper/constants";
const mapDispatchToProps = (dispatch: any) => ({
    createInventoryDispatcher: (obj: object) => dispatch(createInventoryDispatcher(obj)),
    selectedTypeDispatcher: (type: object) => dispatch(selectedTypeDispatcher(type)),
    launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj)),
    getInventoryByIdDispatcher: (type: string, id: string, head: object, orgId: number) =>
      dispatch(getInventoryByIdDispatcher(type, id, head, orgId)),
});

const mapStateToProps = (state: any) => {
    return {
        base64Email: state.storeBase64EmailReducer.data,
        selectedSideOrg: state.selectedSideNavOrgRed.data,
        userContext: state.getOrgByUserRed.context,
    userData: state.getOrgByUserRed.data
    };
};



const CreateInventory = ({
                             open, onClose, showToast, base64Email, selectedSideOrg,userContext,userData, createInventoryDispatcher,
                             selectedTypeDispatcher, launchAssessDispatcher, getInventoryByIdDispatcher
                         }: CreateInventoryProps) => {

    const initialState = {id: 0, name: ''};
    const [isMasterInventory, setIsMasterInventory] = useState('No');
    const [isInventoryLinked, setIsInventoryLinked] = useState('No');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInvName, setIsLoadingInvName] = useState(false);
    const [isToShowBanner, setIsToShowBanner] = useState(false);
    const [inventoryName, setInventoryName] = useState('');
    const [inventoryDesc, setInventoryDesc] = useState('');
    const [selectedBusinessType, setSelectedBusinessType] = useState(initialState);
    const [selectedDPOName, setSelectedDPOName] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedType, setSelectedType] = useState(initialState);
    const [selectedReg, setSelectedReg] = useState(initialState);
    const [selectedMarket, setSelectedMarket] = useState(initialState);
    const [selectedAgency, setSelectedAgency] = useState(initialState);
    const [linkedSelectedType, setLinkedSelectedType] = useState(initialState);
    const [linkedSelectedRelation, setLinkedSelectedRelation] = useState(initialState);
    const [linkedSelectedInventory, setLinkedSelectedInventory] = useState(initialState);
    const [regList, setRegList] = useState([]);
    const [marketList, setMarketList] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
    const [showSpinner, setShowSpinner] = useState(false);
    const [warningInvNameExist, setWarningInvNameExist] = useState(false);
    const [relationList, setRelationList] = useState([]);
    const [launchAssessment, setLaunchAssessment] = useState(false);
    const {osContext, osApi: { getAccessToken }} = useOs();
    const navigate = useNavigate();
    const { axiosInstance } = useAxiosInterceptors()
    const userEmail: string = osContext.userDetails.email;
    const headers = {
        accept: '*/*',
        Authorization: 'Bearer ' + getAccessToken()
    };
    const delayTime = 1000;
    const handleRadioLink = (event: any) => {
        setIsInventoryLinked(event.target.value);
    }
    const isSiteAdmin = userData?.groups.some((group:any) => group?.name === USER_ROLE.SAD);


    const handleRadioMaster = (event: any) => {
        setIsMasterInventory(event.target.value);
    }

    useEffect(() => {
        let delayTimer: any = null;
        if (inventoryName) {
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
                fetchInvName();
            }, delayTime)
        } else {
            setWarningInvNameExist(false);
        }
        return () => {
            clearTimeout(delayTimer);
        };
    }, [inventoryName]);

    useEffect(() => {
        fetchRegionList();
    }, [])

    useEffect(() => {
        if (selectedReg.name !== "") {
            fetchMarketList();
        }
    }, [selectedReg])

    useEffect(() => {
        if (selectedMarket.name !== "") {
            fetchAgencyList();
        }
    }, [selectedMarket])

    useEffect(() => {
        if (linkedSelectedType.name !== "") {
            fetchInventoryList();
            fetchRelationList();
        }
    }, [linkedSelectedType])

    const fetchInvName = () => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + selectedType.name + '?name=' + encodeURIComponent(inventoryName);
        setIsLoadingInvName(true);
        axiosInstance
          .get(apiUrl, {
              headers: headers
          })
          .then(() => {
              setIsLoadingInvName(false);
              setWarningInvNameExist(true);
          })
          .catch((error) => {
              if (error.response.status === 404) {
                  setIsLoadingInvName(false);
                  setWarningInvNameExist(false);
              }
              console.log(error);
          });
    }

    const fetchRegionList = () => {
        if (selectedSideOrg) {
            const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/' + selectedSideOrg.id + '/regions';
            setIsLoading(true);
            axiosInstance
              .get(apiUrl, {
                  headers: headers
              })
              .then((res) => {
                  setIsLoading(false);
                  setIsToShowBanner(false);
                  setRegList(res.data);
              })
              .catch((error) => {
                  setIsLoading(false);
                  setIsToShowBanner(true);
                  console.log(error);
              });
        }
    }

    const fetchMarketList = () => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/regions/' + selectedReg.id + '/markets';
        setIsLoading(true);
        axiosInstance
          .get(apiUrl, {
              headers: headers
          })
          .then((res) => {
              setIsLoading(false);
              setIsToShowBanner(false);
              setMarketList(res.data);
          })
          .catch((error) => {
              setIsLoading(false);
              setIsToShowBanner(true);
              console.log(error);
          });
    }

    const fetchAgencyList = () => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/markets/'+userContext.id +"/" + selectedMarket.id + '/agencies?isAdmin='+isSiteAdmin;
        setIsLoading(true);
        axiosInstance
          .get(apiUrl, {
              headers: headers
          })
          .then((res) => {
              setIsLoading(false);
              setIsToShowBanner(false);
              setAgencyList(res.data);
          })
          .catch((error) => {
              setIsLoading(false);
              setIsToShowBanner(true);
              console.log(error);
          });
    }

    const fetchInventoryList = () => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/inventories?type=' +
          linkedSelectedType.name + '&page=0&size=100&orgId=' + selectedSideOrg.id;
        setIsLoading(true);
        axiosInstance
          .get(apiUrl, {
              headers: headers
          })
          .then((res) => {
              setInventoryList(res.data.content);
              setIsLoading(false);
              setIsToShowBanner(false);
          })
          .catch((error) => {
              console.log(error);
              setIsLoading(false);
              setIsToShowBanner(true);
          });
    }

    const fetchRelationList = () => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/relation/type';
        setIsLoading(true);
        axiosInstance
          .get(apiUrl, {
              headers: headers
          })
          .then((res) => {
              setRelationList(res.data);
              setIsLoading(false);
              setIsToShowBanner(false);
          })
          .catch((error) => {
              console.log(error);
              setIsLoading(false);
              setIsToShowBanner(true);
          });
    }

    const createInventory = () => {
        let apiUrl: string;
        let validationList: any[];
        let postData = {};
        if (selectedType.name === SELECT_TYPES[3].name) {
            postData = {
                name: inventoryName,
                description: inventoryDesc,
                organizationId: selectedSideOrg.id,
                marketId: selectedMarket.id,
                regionId: selectedReg.id,
                agencyId: selectedAgency.id,
                createdBy: userEmail,
                modifiedBy: userEmail,
                isMasterInventory: isMasterInventory === 'Yes',
                businessType: selectedBusinessType.name,
                dataProtectionOfficerName: selectedDPOName,
                address: selectedAddress
            }
        } else {
            postData = {
                name: inventoryName,
                description: inventoryDesc,
                organizationId: selectedSideOrg.id,
                marketId: selectedMarket.id,
                regionId: selectedReg.id,
                agencyId: selectedAgency.id,
                createdBy: userEmail,
                modifiedBy: userEmail,
                isMasterInventory: isMasterInventory === 'Yes'
            }
        }
        let validateFields: object;
        if (isInventoryLinked === 'Yes') {
            apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + linkedSelectedType.name + '/' +
              linkedSelectedInventory.id + '/' + selectedType.name + '?relationTypeId=' + linkedSelectedRelation.id;
            validateFields = {
                ...postData,
                selectedType: selectedType.name,
                linkedSelectedInventory: linkedSelectedInventory.id,
                linkedSelectedRelation: linkedSelectedRelation.id,
                linkedSelectedType: linkedSelectedType.name
            };
        } else {
            apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + selectedType.name;
            validateFields = {
                ...postData,
                selectedType: selectedType.name
            }
        }
        validationList = validate(validateFields);
        setValidationErrors(validationList);
        const found = validationList.map((item: any) => !!item.error).includes(true);
        if (!found) {
            setShowSpinner(true);
            axiosInstance
              .post(apiUrl, postData, {
                  headers: headers,
              })
              .then((res) => {
                  setIsToShowBanner(false);
                  setAgencyList(res.data);
                  if (showToast) {
                      showToast(true);
                  }
                  createInventoryDispatcher && createInventoryDispatcher({
                      ...postData,
                      selectedType: selectedType.name
                  });
                  if (selectedType.name === INVENTORY.TYPE_LE) {
                      onClose();
                      return;
                  }
                  getInventoryByIdDispatcher &&
                  getInventoryByIdDispatcher(selectedType.name, res?.data?.id, headers, res?.data?.organization?.id)
                    .then((res: any) => {
                        if (res) {
                            setShowSpinner(false);
                            setLaunchAssessment(true);
                        }
                    }).catch((err) => {
                      console.log(err);
                      setIsToShowBanner(true);
                      setIsLoading(false);
                  })

              })
              .catch((error) => {
                  setShowSpinner(false);
                  setIsToShowBanner(true);
                  console.log(error);
              });
        }
    }

    const updateValidationList = (fieldName: string) => {
        return validationErrors?.filter((item: any) => item.name !== fieldName);

    }

    const handleBusinessType = (e: any) => {
        setSelectedBusinessType(e.target.value);
        setValidationErrors(updateValidationList('businessType'));
    }

    const handleDPOName = (e: any) => {
        setSelectedDPOName(e.target.value);
        setValidationErrors(updateValidationList('dataProtectionOfficerName'));
    }

    const handleAddress = (e: any) => {
        setSelectedAddress(e.target.value);
        setValidationErrors(updateValidationList('address'));
    }

    const handleType = (e: any) => {
        setSelectedType(e.target.value);
        setValidationErrors(updateValidationList('selectedType'));
    }

    const handleInvName = (e: any) => {
        setInventoryName(e.target.value);
        setValidationErrors(updateValidationList('name'));
    }

    const handleRegion = (e: any) => {
        setSelectedReg(e.target.value);
        setSelectedMarket(initialState);
        setSelectedAgency(initialState);
        setValidationErrors(updateValidationList('regionId'));
    }

    const handleMarket = (e: any) => {
        setSelectedMarket(e.target.value);
        setSelectedAgency(initialState);
        setValidationErrors(updateValidationList('marketId'));
    }

    const handleAgency = (e: any) => {
        setSelectedAgency(e.target.value);
        setValidationErrors(updateValidationList('agencyId'));
    }

    const handleLinkedType = (e: any) => {
        setLinkedSelectedType(e.target.value);
        setLinkedSelectedRelation(initialState);
        setLinkedSelectedInventory(initialState);
        setValidationErrors(updateValidationList('linkedSelectedType'));
    }

    const handleLinkedRelation = (e: any) => {
        setLinkedSelectedRelation(e.target.value);
        setLinkedSelectedInventory(initialState);
        setValidationErrors(updateValidationList('linkedSelectedRelation'));
    }

    const handleLinkedInv = (e: any) => {
        setLinkedSelectedInventory(e.target.value);
        setValidationErrors(updateValidationList('linkedSelectedInventory'));
    }

    const handleLaunchAssessment = () => {
        selectedTypeDispatcher && selectedTypeDispatcher(selectedType);
        launchAssessDispatcher && launchAssessDispatcher({openAssess: true, type: selectedType});
        navigate('/pia/assessment')
    };

    return (
      <WppSideModal open={open} size={'2xl'} onWppSideModalClose={onClose}>
          <h3 slot="header">Create Inventory</h3>
          <div className={styles.inventoryBody} slot="body">
              <div className={styles.loadingArea}>
                  {isLoading && <WppProgressIndicator
                    className={styles.customLoader}
                    variant="bar"/>}
                  <WppBanner id="banner" type="information" show={isToShowBanner} className={styles.banner}>
                      Unable to fetch information. Please refresh or try after some time.
                  </WppBanner>
              </div>
              <Container maxWidth="xl">
                  <Grid container
                        spacing={{xs: 2, md: 3}}
                        columns={{xs: 4, sm: 8, md: 12}}
                        rowSpacing={10}>
                      <Grid item xs={12} sm={12} md={12}>
                          <p>Do you want to create this inventory as master inventory?</p>
                          <div className={styles.masterSubDesc}>{TEXT.MASTER_INV_SUB_DESC}</div>
                          <WppRadioGroup className={styles.radioGroup} onWppChange={handleRadioMaster}>
                              <WppRadio
                                className={styles.radio}
                                disabled={launchAssessment}
                                required
                                name="inventory-master"
                                value="Yes"
                                labelConfig={{text: 'Yes'}}/>
                              <WppRadio
                                className={styles.radio}
                                disabled={launchAssessment}
                                checked={isMasterInventory === 'No'}
                                required name="inventory-master"
                                value="No"
                                labelConfig={{text: 'No'}}/>
                          </WppRadioGroup>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                          <WppLabel
                            className={styles.label}
                            config={{text: 'Type'}}
                            htmlFor="type"
                            typography="s-strong"
                          />
                          <WppSelect
                            id="type"
                            disabled={launchAssessment}
                            placeholder="Select Type"
                            value={selectedType.name}
                            size="s"
                            message={validationErrors && validationErrors.find((item: any) => item.name === 'selectedType')?.error || ''}
                            messageType={validationErrors && validationErrors.find((item: any) => item.name === 'selectedType')?.error ? 'error' : undefined}
                            onWppChange={handleType}
                          >
                              {SELECT_TYPES.slice(0, 2).concat(SELECT_TYPES.slice(3)).map((item, idx) => {
                                  return (
                                    <WppListItem key={idx} value={item}>
                                        <p slot="label">{convertToTitleCase(item.name)}</p>
                                    </WppListItem>
                                  );
                              })}
                          </WppSelect>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                          <WppLabel
                            className={styles.label}
                            config={{text: 'Inventory Name'}}
                            htmlFor="invetory-name"
                            typography="s-strong"
                          />
                          <WppInput
                            disabled={selectedType.name === '' || launchAssessment}
                            id="invetory-name"
                            placeholder="Name Of Inventory"
                            size={'s'}
                            message={validationErrors && validationErrors.find((item: any) => item.name === 'name')?.error || ''}
                            messageType={validationErrors && validationErrors.find((item: any) => item.name === 'name')?.error ? 'error' : undefined}
                            value={inventoryName}
                            onWppChange={handleInvName}
                          />
                          <div className={styles.warningInvSec}>
                              {isLoadingInvName && <WppSpinner size="s" color={'black'}/>}
                              {warningInvNameExist && <p className={styles.warningInvNameExist}>
                                  Inventory name already exists. Please choose a different name.
                              </p>}
                          </div>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                          <WppLabel
                            className={styles.label}
                            config={{text: 'Region'}}
                            htmlFor="region"
                            typography="s-strong"
                          />
                          <WppSelect
                            id="region"
                            onWppChange={handleRegion}
                            placeholder="Select Region"
                            disabled={selectedSideOrg.name === "" || launchAssessment}
                            value={selectedReg.name}
                            withSearch={true}
                            size="s"
                            message={validationErrors && validationErrors.find((item: any) => item.name === 'regionId')?.error || ''}
                            messageType={validationErrors && validationErrors.find((item: any) => item.name === 'regionId')?.error ? 'error' : undefined}
                          >
                              {regList?.length ? regList?.map((item: { name: string }, idx: number) => (
                                <WppListItem key={idx} value={item}>
                                    <p slot="label">{item.name}</p>
                                </WppListItem>
                              )) : <WppListItem>
                                  <p slot="label">No Data</p>
                              </WppListItem>}
                          </WppSelect>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                          <WppLabel
                            className={styles.label}
                            config={{text: 'Market'}}
                            htmlFor="market"
                            typography="s-strong"
                          />
                          <WppSelect
                            id="market"
                            onWppChange={handleMarket}
                            placeholder="Select Market"
                            disabled={selectedReg.name === "" || launchAssessment}
                            value={selectedMarket.name}
                            withSearch={true}
                            size="s"
                            message={validationErrors && validationErrors.find((item: any) => item.name === 'marketId')?.error || ''}
                            messageType={validationErrors && validationErrors.find((item: any) => item.name === 'marketId')?.error ? 'error' : undefined}
                          >
                              {marketList?.length ? marketList?.map((item: { name: string }, idx: number) => (
                                <WppListItem key={idx} value={item}>
                                    <p slot="label">{item.name}</p>
                                </WppListItem>
                              )) : <WppListItem>
                                  <p slot="label">No Data</p>
                              </WppListItem>}
                          </WppSelect>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                          <WppLabel
                            className={styles.label}
                            config={{text: 'Agency'}}
                            htmlFor="agency"
                            typography="s-strong"
                          />
                          <WppSelect
                            id="agency"
                            onWppChange={handleAgency}
                            placeholder="Select Agency"
                            disabled={selectedMarket.name === "" || launchAssessment}
                            value={selectedAgency.name}
                            withSearch={true}
                            size="s"
                            message={validationErrors && validationErrors.find((item: any) => item.name === 'agencyId')?.error || ''}
                            messageType={validationErrors && validationErrors.find((item: any) => item.name === 'agencyId')?.error ? 'error' : undefined}
                          >
                              {agencyList?.length ? agencyList?.map((item: { name: string }, idx: number) => (
                                <WppListItem key={idx} value={item}>
                                    <p slot="label">{item.name}</p>
                                </WppListItem>
                              )) : <WppListItem>
                                  <p slot="label">No Data</p>
                              </WppListItem>}
                          </WppSelect>
                      </Grid>
                      {selectedType.name === SELECT_TYPES[3].name &&
                        <>
                            <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                                <WppLabel
                                  className={styles.label}
                                  config={{text: 'Business Type'}}
                                  htmlFor="business-type"
                                  typography="s-strong"
                                />
                                <WppSelect
                                  id="business-type"
                                  disabled={launchAssessment}
                                  placeholder="Select Business Type"
                                  value={selectedBusinessType.name}
                                  size="s"
                                  message={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'businessType')?.error || ''}
                                  messageType={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'businessType')?.error ? 'error' : undefined}
                                  onWppChange={handleBusinessType}
                                >
                                    {BUSINESS_TYPES.map((item, idx) => {
                                        return (
                                          <WppListItem key={idx} value={item}>
                                              <p slot="label">{item.name}</p>
                                          </WppListItem>
                                        );
                                    })}
                                </WppSelect>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                                <WppLabel
                                  className={styles.label}
                                  config={{text: 'Data Protection Officer Name'}}
                                  htmlFor="dpo-name"
                                  typography="s-strong"
                                />
                                <WppInput
                                  id="dpo-name"
                                  disabled={launchAssessment}
                                  placeholder="Data Protection Officer Name"
                                  size={'s'}
                                  message={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'dataProtectionOfficerName')?.error || ''}
                                  messageType={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'dataProtectionOfficerName')?.error ? 'error' : undefined}
                                  value={selectedDPOName}
                                  onWppChange={handleDPOName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} paddingTop={'5rem !important'}>
                                <WppLabel
                                  className={styles.label}
                                  config={{text: 'Address'}}
                                  htmlFor="address"
                                  typography="s-strong"
                                />
                                <WppInput
                                  id="address"
                                  disabled={launchAssessment}
                                  placeholder="Enter The Address"
                                  size={'s'}
                                  message={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'address')?.error || ''}
                                  messageType={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'address')?.error ? 'error' : undefined}
                                  value={selectedAddress}
                                  onWppChange={handleAddress}
                                />
                            </Grid>
                        </>}
                      <Grid item xs={12} sm={12} md={12} paddingTop={'5rem !important'}>
                          <WppLabel
                            className={styles.label}
                            config={{text: 'Description'}}
                            htmlFor="description"
                            typography="s-strong"
                            optional={true}
                          />
                          <WppTextareaInput
                            id="description"
                            disabled={launchAssessment}
                            placeholder="Enter Description"
                            warningThreshold={100}
                            maxMessageLength={100}
                            charactersLimit={100}
                            value={inventoryDesc}
                            onWppChange={(e) => setInventoryDesc(e.target.value)}
                          />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className={styles.linkedInventory}>
                          <p>Would you like to link the new inventory to any existing inventory?</p>
                          <WppRadioGroup className={styles.radioGroup} onWppChange={handleRadioLink}>
                              <WppRadio
                                className={styles.radio}
                                disabled={launchAssessment}
                                required
                                name="inventory-link"
                                value="Yes"
                                labelConfig={{text: 'Yes'}}/>
                              <WppRadio
                                className={styles.radio}
                                disabled={launchAssessment}
                                checked={isInventoryLinked === 'No'}
                                required
                                name="inventory-link"
                                value="No"
                                labelConfig={{text: 'No'}}/>
                          </WppRadioGroup>
                      </Grid>
                      {isInventoryLinked === 'Yes' &&
                        <>
                            <Grid item xs={12} sm={4} md={4} className={styles.linkedInventorySelect}
                                  marginBottom={'5rem'}>
                                <WppLabel
                                  className={styles.label}
                                  config={{text: 'Type'}}
                                  htmlFor="link_type"
                                  typography="s-strong"
                                />
                                <WppSelect
                                  id="link_type"
                                  disabled={launchAssessment}
                                  placeholder="Select Type"
                                  value={linkedSelectedType.name}
                                  size="s"
                                  onWppChange={handleLinkedType}
                                  message={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'linkedSelectedType')?.error || ''}
                                  messageType={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'linkedSelectedType')?.error ? 'error' : undefined}
                                >
                                    {SELECT_TYPES.map((item, idx) => {
                                        return (
                                          <WppListItem key={idx} value={item}>
                                              <p slot="label">{convertToTitleCase(item.name)}</p>
                                          </WppListItem>
                                        );
                                    })}
                                </WppSelect>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} className={styles.linkedInventorySelect}
                                  marginBottom={'5rem'}>
                                <WppLabel
                                  className={styles.label}
                                  config={{text: 'Relation'}}
                                  htmlFor="link_relation"
                                  typography="s-strong"
                                />
                                <WppSelect
                                  id="link_relation"
                                  onWppChange={handleLinkedRelation}
                                  placeholder={'Select Relation'}
                                  disabled={linkedSelectedType.name === '' || launchAssessment}
                                  value={linkedSelectedRelation.name}
                                  withSearch={true}
                                  size="s"
                                  message={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'linkedSelectedRelation')?.error || ''}
                                  messageType={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'linkedSelectedRelation')?.error ? 'error' : undefined}
                                >
                                    {relationList.map((item: { idx: number, name: string }) => {
                                        return (
                                          <WppListItem key={item.idx} value={item}>
                                              <p slot="label">{item.name}</p>
                                          </WppListItem>
                                        );
                                    })}
                                </WppSelect>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} className={styles.linkedInventorySelect}
                                  marginBottom={'5rem'}>
                                <WppLabel
                                  className={styles.label}
                                  config={{text: 'Inventory'}}
                                  htmlFor="link_inventory"
                                  typography="s-strong"
                                />
                                <WppSelect
                                  id="link_inventory"
                                  onWppChange={handleLinkedInv}
                                  placeholder={'Select Inventory'}
                                  disabled={linkedSelectedRelation.name === '' || launchAssessment}
                                  value={linkedSelectedInventory.name}
                                  withSearch={true}
                                  size="s"
                                  message={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'linkedSelectedInventory')?.error || ''}
                                  messageType={validationErrors && validationErrors
                                    .find((item: any) => item.name === 'linkedSelectedInventory')?.error ? 'error' : undefined}
                                >
                                    {inventoryList && inventoryList.map((item: { name: string }, idx: number) => {
                                        return (
                                          <WppListItem key={idx} value={item}>
                                              <p slot="label">{item.name}</p>
                                          </WppListItem>
                                        );
                                    })}
                                </WppSelect>
                            </Grid>
                        </>}
                  </Grid>
              </Container>
          </div>
          <div className={styles.btnSection} slot="actions">
              <WppButton
                className={styles.customBtnSecondary}
                id={'create_inventory'}
                disabled={warningInvNameExist || launchAssessment}
                variant="secondary"
                loading={showSpinner}
                onClick={createInventory}>
                  Create Inventory
              </WppButton>
              {selectedType.name !== INVENTORY.TYPE_LE && <WppButton
                className={styles.customBtnSecondary}
                id={"create_inventory"}
                disabled={!launchAssessment}
                variant="secondary"
                onClick={handleLaunchAssessment}>
                  Launch Assessment
              </WppButton>}
          </div>
      </WppSideModal>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInventory);
