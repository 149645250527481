import React from "react"
import {
  WppAccordion,
  WppIconInfo,
  WppPill,
  WppRadio,
  WppTextareaInput,
  WppTooltip,
  WppTypography,
  WppIconDownload
} from "@wppopen/components-library-react"
import styles from "./QueAns.module.scss"
import { QUE_TYPE } from "../../helper/constants"
import { uploadFileName } from "../../helper/Helper"
import appConfig from "../../app.config";

export const QueAns = ({ rowDetails }: any) => {
  const renderAttachment = (attachmentFile: Array<{ filename: string }> | null) => {
    if (attachmentFile && attachmentFile.length) {
      return (
        <div className={styles.fileName}>
          <WppTypography tag="p" type={"s-strong"} className={styles.headerQueAns}>
            Attached File(s) :{" "}
          </WppTypography>
          {attachmentFile.map(({ filename }, index) => {
            return (
              <div>
                <WppTypography className={styles.fileName}>
                  {index + 1}. &nbsp;{uploadFileName(filename)}
                </WppTypography>
                <a
                  href={appConfig.RA_HOST_URL + "/api/responses/download?filename=" + filename}
                  className={styles.downloadLink}
                >
                  <WppIconDownload style={{ cursor: "pointer" }} color="primary" size="s" />
                </a>
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  const renderSingleSelect = (data: any) => {
    const response = data?.responseValue
    const type = data?.responseType
    const fileName = data?.filename
    const splitVal = response?.split("$$")
    return (
      <>
        <WppRadio
          className={styles.radio}
          required={true}
          disabled={true}
          checked={true}
          labelConfig={{ text: splitVal[0] }}
          name="options"
        />
        {(type === QUE_TYPE.SINGLE_SELECT_TEXT ||
          type === QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL ||
          type === QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL_ATTACHMENT || type === QUE_TYPE.SINGLE_SELECT_TEXT_CONDITIONAL) && splitVal[1] && (
            <WppTextareaInput
              disabled={true}
              className={styles.response}
              name="text-response"
              warningThreshold={200}
              maxMessageLength={200}
              charactersLimit={200}
              value={splitVal[1]}
            />
          )}
        {renderAttachment(data?.attachments)}
      </>
    )
  }

  const renderMultiSelect = (data: any,) => {
    const response = data?.responseValue
    const type = data?.responseType
    const fileName = data?.filename
    let pillVal
    let textVal

    const result = response ?? ""
    const splitValue = result.split("$$")
    const [checkBoxValue = [], inputValue = ""] = splitValue
    const responseValue = checkBoxValue?.split("|")

    if (
      type === QUE_TYPE.MULTI_SELECT_TEXT ||
      type === QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL ||
      type === QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL_ATTACHMENT ||
      type === QUE_TYPE.MULTI_SELECT_TEXT_CONDITIONAL
    ) {
      pillVal = responseValue
      textVal = inputValue
    } else {
      pillVal = responseValue
    }
    return (
      <>
        {pillVal?.map((option: string, idx: number) => (
          <WppPill
            className={styles.customPill}
            title={option}
            key={`pills-${idx}`}
            disabled={true}
            label={option}
            value={option}
          />
        ))}
        {(type === QUE_TYPE.MULTI_SELECT_TEXT || type === QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL || type === QUE_TYPE.MULTI_SELECT_TEXT_CONDITIONAL) && textVal && (
          <WppTextareaInput
            disabled={true}
            className={styles.response}
            name="text-response"
            warningThreshold={200}
            maxMessageLength={200}
            charactersLimit={200}
            value={textVal}
          />
        )}
        {renderAttachment(data?.attachments)}
      </>
    )
  }

  const renderText = (data: any) => {
    const response = data?.responseValue
    const fileName = data?.filename
    return (
      <>
      <WppTextareaInput
        disabled={true}
        className={styles.response}
        name="text-response"
        warningThreshold={200}
        maxMessageLength={200}
        charactersLimit={200}
        value={response}
      />
      {renderAttachment(data?.attachments)}
      </>
    )
  }

  const renderResponse = (response: { responseType: string; responseValue: string }) => {
    switch (response.responseType) {
      case QUE_TYPE.SINGLE_SELECT:
      case QUE_TYPE.SINGLE_SELECT_TEXT:
      case QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL:
      case QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL_ATTACHMENT:
      case QUE_TYPE.SINGLE_SELECT_TEXT_CONDITIONAL:
        return renderSingleSelect(response)
      case QUE_TYPE.MULTI_SELECT:
      case QUE_TYPE.MULTI_SELECT_TEXT:
      case QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL:
      case QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL_ATTACHMENT:
      case QUE_TYPE.MULTI_SELECT_TEXT_CONDITIONAL:
        return renderMultiSelect(response)
      case QUE_TYPE.TEXT:
      case QUE_TYPE.API_DROPDOWN:
      case QUE_TYPE.DROPDOWN:
      case QUE_TYPE.PRESELECTED:
        return renderText(response)
      default:
        return response.responseValue
    }
  }

  return (
    <>
      {Array.isArray(rowDetails) && rowDetails.length ? (
        <>
          {rowDetails.map((assessment: { assessmentName: string; details: object[] }) => {
            return (
              <WppAccordion
                size="m"
                className={styles.accordStyle}
                withDivider={false}
                key={`accordion-${assessment.assessmentName}`}
              >
                <WppTypography
                  type="m-strong"
                  slot="header"
                  title={assessment.assessmentName}
                  className={styles.accordHeader}
                  key={`header-${assessment.assessmentName}`}
                >
                  {assessment.assessmentName}
                </WppTypography>
                <div key={`typography-${assessment.assessmentName}`} className={styles.outerAccord}>
                  {assessment.details.map((category: any) => {
                    return (
                      <WppAccordion size="m" withDivider={true} key={`child-accordion-${category.categoryName}`}>
                        <WppTypography
                          type="m-strong"
                          slot="header"
                          className={styles.accordHeader}
                          key={`child-header-${category.categoryName}`}
                        >
                          {category.categoryName + " (" + category.questionAnswers.length + ")"}
                        </WppTypography>
                        <div key={`typography-${category.categoryName}`} className={styles.catDescription}>
                          {category.questionAnswers.map((que: any) => {
                            return (
                              <div key={`row-${que.question.id}`} className={styles.secInventoryDetails}>
                                <div key={`que-${que.question.id}`} className={styles.questionHolder}>
                                  <span key={`key-${que.question.id}`} className={styles.queTag}>
                                    {que.question.sequence}:{" "}
                                  </span>
                                  {que.question.description + " "}
                                  {que.question.subDescription?.trim().length > 0 && (
                                    <WppTooltip
                                      key={`tooltip-${que.question.id}`}
                                      header="Description"
                                      text={que.question.subDescription}
                                    >
                                      <WppIconInfo key={`icon-${que.question.id}`} />
                                    </WppTooltip>
                                  )}
                                </div>
                                <p key={`res-${que.question.id}`} className={styles.queTag}>
                                  Response:
                                </p>
                                {que.response !== null ? (
                                  <div className={styles.response}>{renderResponse(que.response)}</div>
                                ) : (
                                  <div className={styles.noResponse}>No Response</div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </WppAccordion>
                    )
                  })}
                </div>
              </WppAccordion>
            )
          })}
        </>
      ) : (
        <div className={styles.assessmentNotFound}>No Assessment Found</div>
      )}
    </>
  )
}

export default QueAns
